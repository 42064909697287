import React, { useEffect, useState, useRef } from "react";
import styles from "../Modal.module.scss";
import MagnifyImage from "../../Shared/MagnifyImage";
import ModalClose from "../ModalClose";
import { getPriceInCurrency } from "../../../utils/utils";
import { useDiary } from "../../../Context/ContextDiary";
import { useBuyer } from "../../../Context/ContextBuyer";
import Loader from "../../Shared/Loader";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CardSKUQuantity from "../../Card/CardSKU/CardSKUQuantity";
import CardSKUAddToOrder from "../../Card/CardSKU/CardSKUAddToOrder";
import config from "../../../config/config";
import { gsap } from "gsap";

export default function ModalProduct({ selectedProduct }) {
  // importing sku from props and not from the context to be sure that the sku is coming from a click
  // TODO: the logic has to be changed
  const productModalRef = useRef(null);
  const modalProductLoaderRef = useRef(null);
  const [displayImageIndex, setDisplayImageIndex] = useState(0);

  const [quantity, setQuantity] = useState({});
  const [areSizesEdited, setAreSizesEdited] = useState(false);

  const { product, basket, order, productModal, diaryActions } = useDiary();
  const { buyer } = useBuyer();

  console.log(product, selectedProduct);

  useEffect(() => {
    let modalPos = null;
    if (product && product?.id !== selectedProduct?.id) {
      modalPos = productModalRef?.current?.getBoundingClientRect();
    }
    if (modalPos)
      gsap.to(window, {
        duration: config.scrollTiming / 1000,
        scrollTo: modalPos.top + window.pageYOffset,
      });
    // console.log(product);
    if (product && product.skus) {
      let sizes = {};

      product.skus?.length &&
        product.skus.map((el) => {
          sizes = Object.assign(sizes, {
            [el.size]:
              selectedProduct?.quantity && selectedProduct.quantity[el.size]
                ? typeof selectedProduct.quantity[el.size] === "number"
                  ? {
                      quantity: selectedProduct.quantity[el.size],
                      skuId: el.id,
                    }
                  : selectedProduct.quantity[el.size]
                : { quantity: 0, skuId: el.id },
          });
        });

      setQuantity(sizes);
    }
  }, [product]);

  useEffect(() => {
    if (selectedProduct) {
      setQuantity({});

      diaryActions.getProductData(selectedProduct.id);
    }
    setDisplayImageIndex(0);
    return () => setAreSizesEdited(false);
  }, [selectedProduct]);

  const handleUpdateQuantity = (operation, size) => {
    // operation: 'add' | 'remove'

    setAreSizesEdited(true);
    if (quantity[size].quantity === 0 && operation === "remove") return;
    setQuantity({
      ...quantity,
      [size]: {
        quantity:
          operation === "add"
            ? quantity[size].quantity + 1
            : quantity[size].quantity - 1,
        skuId: quantity[size].skuId,
      },
    });
  };

  if (!product)
    return (
      <div id="loader" ref={modalProductLoaderRef}>
        <Loader
          type="MutatingDots"
          color={"black"}
          secondaryColor={"grey"}
          height={150}
          width={150}
        />
      </div>
    );

  const prices =
    product && productModal
      ? getPriceInCurrency(
          product.prices,
          productModal.currency.retail,
          productModal.currency.wholesale
        )
      : null;

  const handleAddRelatedProduct = (product) => {
    diaryActions.setModalProduct(true, product);
  };

  const calculateQuantity = (s) => {
    return typeof quantity[s] === "number" ? quantity[s] : quantity[s].quantity;
  };

  const calculateTotal = (type) => {
    const totalQty = Object.values(quantity)
      .map((l) => l.quantity)
      .reduce((acc, el) => acc + el, 0);
    if (type === "W") {
      return prices?.price_wholesale * (totalQty || 0);
    } else if (type === "R") {
      return prices?.price_retail * (totalQty || 0);
    }
  };

  return (
    <div
      className={`${styles.modal_product_wrapper} ${styles.dark}`}
      ref={productModalRef}
    >
      <div
        className={`${styles.modal_product} ${styles.modal_look}`}
        onClick={(e) => e.stopPropagation()}
      >
        <ModalClose close={() => diaryActions.setModalProduct(false)} />
        <div className={styles.image_container}>
          <MagnifyImage
            imageUrl={product?.images?.[displayImageIndex]?.url}
            alt={product.name}
            // maxHeight={
            //   product?.images?.[displayImageIndex]?.thumbnails
            //     ?.large?.height
            // }
            // maxWidth={
            //   pro?.orderedImages?.[displayImageIndex]?.thumbnails
            //     ?.large?.width
            // }
          />
          <div className={styles.img_dots_container}>
            {product.images?.map((img, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.img_dot} ${
                    displayImageIndex === index ? styles.active : ""
                  }`}
                  onClick={() => setDisplayImageIndex(index)}
                />
              );
            })}
          </div>
        </div>

        <div
          className={`${styles.content_container} ${styles.sku_content_container}`}
        >
          <div className={styles.header}>
            <h2>{product?.name}</h2>
          </div>
          <span>{product?.product_identifier}</span>
          <p>{product?.description}</p>
          <div className={styles.price_wrap}>
            <div className={styles.price_one_wrap}>
              <p className={styles.price_main_currency}>
                W {prices?.price_currency} {prices?.price_wholesale}
              </p>
              <p className={styles.price_sub_currency}>
                R {prices?.price_currency_retail} {prices?.price_retail}
              </p>
            </div>
            <div>
              <h4>total</h4>
              <p className={styles.price_main_currency}>
                W {prices?.price_currency} {calculateTotal("W")}
              </p>
              <p className={styles.price_sub_currency}>
                R {prices?.price_currency_retail} {calculateTotal("R")}
              </p>
            </div>
          </div>

          {selectedProduct.size && (
            <p className={styles.price}>
              Size:{" "}
              {selectedProduct.size.map((size, index) => {
                return <div key={index}>{size.name}</div>;
              })}
            </p>
          )}

          <div className={styles.sizes_wrap}>
            {quantity &&
              Object.keys(quantity).map((s, index) => {
                return (
                  <div className={styles.sizes} key={index}>
                    <p className={styles.size}>{s}</p>
                    <button onClick={() => handleUpdateQuantity("add", s)}>
                      +
                    </button>

                    <span
                      className={
                        calculateQuantity(s) > 0 ? styles.qtyActive : ""
                      }
                    >
                      {calculateQuantity(s)}
                    </span>
                    <button onClick={() => handleUpdateQuantity("remove", s)}>
                      -
                    </button>
                  </div>
                );
              })}
            {quantity && areSizesEdited ? (
              <div className={styles.sizes_update_wrap}>
                <button
                  onClick={async () => {
                    let filteredQuantity = {};
                    Object.keys(quantity)
                      .filter((s) => quantity[s].quantity > 0)
                      .map((s) => {
                        Object.assign(filteredQuantity, {
                          [s]: {
                            quantity: quantity[s].quantity,
                            skuId: quantity[s].skuId,
                            wholesale: prices?.price_wholesale || 0,
                            retail: prices?.price_retail || 0,
                          },
                        });
                      });
                    const b = await diaryActions.updateQuantity(
                      basket.id,
                      selectedProduct.id,
                      filteredQuantity,
                      productModal?.product
                    );

                    if (b) {
                      console.log("updated qty");
                      diaryActions.getBasket(buyer.mail, true);
                      diaryActions.setModalProduct(false);
                    }
                  }}
                >
                  Update
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.related_products_wrap}>
        <h3>related products</h3>
        <div className={styles.related_products_row}>
          {product?.recommandations?.map((e) => {
            const productIsAlreadyAdded = order.find((p) => p.id === e.id);
            if (e.image) {
              return (
                <div
                  className={styles.related_product_card}
                  key={e.image}
                  onClick={() => handleAddRelatedProduct(e)}
                >
                  <img src={e.image} alt={"product"} />
                  {productIsAlreadyAdded ? (
                    <CardSKUQuantity
                      quantity={productIsAlreadyAdded.quantity_total || 0}
                    />
                  ) : (
                    <CardSKUAddToOrder product={e} />
                  )}
                </div>
              );
            }
            return null;
            //   return (<div className={styles.images_container}>
            //   {recommandations.map((recoSku, index) => {
            //     return (
            //       <div
            //         className={styles.one_image}
            //         key={index}
            //         onClick={() => diaryActions.setModalProduct(true, recoSku)}
            //       >
            //         <img
            //           src={recoSku?.orderedImages?.[0]?.url}
            //           alt={recoSku.skuId}
            //         />
            //       </div>)}
            //   })}
            // </div>
          })}
        </div>
      </div>
    </div>
  );
}
