import React from "react";
import styles from "./Welcome.module.scss";

export default function WelcomeVideo({
  videoRef,
  isPlaying,
  // togglePlayPause,
  notifyVideoEnd,
}) {
  const videoLoadStart = () => {
    console.log("video started loading");
  };

  const videoLoaded = () => {
    console.log("video ended loading");
  };

  return (
    <div
      className={`${styles.video_wrapper} 
    ${styles.pause}`}
    >
      <video
        ref={videoRef}
        className="player"
        preload="auto"
        src="https://storage.googleapis.com/paco-assets/PR_FW22_Horizontal_VALIDE_Small_Size_5mbits%20(1).mp4"
        // playsInline
        // muted
        onLoadStart={videoLoadStart}
        onLoadedData={videoLoaded}
        onEnded={notifyVideoEnd}
      ></video>
    </div>
  );
}
