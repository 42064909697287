import React, {useRef, useState} from "react";
import styles from "../../Modal.module.scss";
import {useBuyer} from "../../../../Context/ContextBuyer";
import {useHistory} from "react-router-dom";

export default function Disguise() {
  const [isLoginClicked, setisLoginClicked] = useState(false);

  const [email, setEmail] = useState(null);
  const history = useHistory();
  const formRef = useRef();

  const {buyerActions} = useBuyer();

  return (
    <div className={styles.form_container}>
      <form
        ref={formRef}
        onSubmit={async (e) => {
          setisLoginClicked(true);
          e.preventDefault();

          if (!isLoginClicked) {
            const res = await buyerActions.applyDisguise(email);

            if (res.ok) {
              history.push("/main");
            }
            setisLoginClicked(false);
          }
        }}
      >
        <h1 className={styles.center}>
          <p className={styles.welcome_message}>
            Hi Admin! You are about logging to the platform as a buyer. Please,
            provide a valid buyer's email below
          </p>
        </h1>
        <div className={styles.email_wrap}>
          <input
            required
            type="email"
            className={styles.fw_input}
            placeholder={"Email*"}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={styles.button_container}>
          <input
            className={styles.submit_btn}
            type="submit"
            value={"LOGIN"}
            submit
          />

          {/* <input
            className={styles.request_btn}
            type="submit"
            value={"CREATE ACCOUNT"}
            submit
          /> */}
        </div>
      </form>
    </div>
  );
}
