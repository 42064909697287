import React, { useState, useEffect, useRef } from "react";
import styles from "./OverlayMenu.module.scss";
import Logo from "../Logo/Logo";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../assets/button-remove.svg";
import { useDiary } from "../../Context/ContextDiary";
import { ReactComponent as ShopBag } from "../../assets/Union.svg";
import { useBuyer } from "../../Context/ContextBuyer";
import { useGlobal } from "../../Context/ContextGlobal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import config from "../../config/config";
import { ReactComponent as LogOut } from "../../assets/log out.svg";
import userGuideRect from "../../assets/userGuideRect.svg";
import getReportIcon from "../../assets/getReportIcon.svg";

import ValidateGif from "../../assets/ValidateGIF.gif";
import AddGif from "../../assets/AddGif.gif";
import ConfigureGif from "../../assets/ConfigureGIF.gif";

export default function OverlayMenu() {
  const { order } = useDiary();
  const { buyer, isDisguise, buyerActions } = useBuyer();
  const { openMenu, globalActions } = useGlobal();
  const [showVideo, setShowVideo] = useState(null);
  const [userGuide, setUserGuide] = useState(false);
  const containerRef = useRef(null);

  const handleCloseMenu = () => {
    setTimeout(() => globalActions.openMenu(false), 700);
    const tl = gsap.timeline();
    tl.to(containerRef.current, { duration: 0.7, y: "-100%" });
    tl.to(containerRef.current, {
      display: "none",
    });
    tl.add(() => {
      setShowVideo(null);
      setUserGuide(false);
    });
  };

  useEffect(() => {
    return () => {
      handleCloseMenu();
    };
  }, []);
  useEffect(() => {
    if (openMenu) {
      gsap.to(containerRef.current, {
        display: "block",
      });
      gsap.fromTo(
        containerRef.current,
        { y: "-100%" },
        { duration: 0.7, y: 0 }
      );
    }
  }, [openMenu]);

  const handleScrollDown = () => {
    setShowVideo(null);
    setUserGuide(false);
    globalActions.openMenu(false);
    gsap.to(window, {
      duration: config.scrollTiming / 1000,
      scrollTo: "#scroll-next",
    });
  };

  if (!openMenu) return <></>;
  return (
    <div className={styles.overlay_menu_container} ref={containerRef}>
      <div className={styles.overlay_menu_back}>
        <p>FW22</p>
        <div className={styles.contacts_and_menu_wrap}>
          {/* <div className={styles.contacts_wrap}>
            <p>Your contact : First Name Last Name</p>
            <p>firstlast@pacorabanne.com</p>
            <p>+33 (1) 00 00 00 00</p>
          </div> */}
          <div className={styles.menu_wrap}>
            {/* {buyer?.isAdmin && !isDisguise && (
              <div
                className={styles.orders_list_button}
                style={{
                  marginBottom: 15,
                }}
                onClick={() => {
                  globalActions.openAdminOrders(true);
                }}
              >
                CLIENTS LIST
              </div>
            )} */}
            <a
              href={
                "https://storage.googleapis.com/paco-assets/FW22%20-%20CORE%20OFFER%20RTW%20%26%20ACCESS%20-%2024.01.22%20VF.pdf"
              }
              target="_blank"
              rel="noreferrer"
              download="FW22_CORE_OFFER_RTW_ACC"
              className={styles.getReportButton}
            >
              DOWNLOAD COLLECTION
              <br />
              STRATEGY REPORT
              <img
                src={getReportIcon}
                alt={"get report"}
                width={"19px"}
                style={{ marginLeft: "64px" }}
              />
            </a>
            <LogOut onClick={buyerActions.logout} />
          </div>
        </div>
      </div>
      <div className={styles.overlay_menu_front}>
        <div className={styles.navmenu_row}>
          <Logo dark />
          <div className={styles.navmenu_actions}>
            {buyer?.isAdmin && !isDisguise && (
              <div
                className={styles.orders_list_button}
                style={{
                  marginRight: 35,
                }}
                onClick={() => {
                  globalActions.openAdminOrders(true);
                }}
              >
                CLIENTS LIST
              </div>
            )}
            <div className={styles.shop_bag_wrap} onClick={handleScrollDown}>
              <ShopBag style={{ filter: "invert(100%)" }} />
              <span>{order?.length || 0}</span>
            </div>
            <Close
              style={{ filter: "invert(100%)", cursor: "pointer" }}
              onClick={handleCloseMenu}
            />
          </div>
        </div>
        <div className={styles.overlay_content}>
          <div className={styles.overlay_content_left}>
            {userGuide ? (
              <div className={styles.userGuideContainer}>
                <div className={styles.guideRow}>
                  <img
                    src={AddGif}
                    alt={"user guide"}
                    style={{
                      width: "180px",
                      heigth: "105px",
                    }}
                  />
                  <p>Add product to ORDER</p>
                </div>
                <div className={styles.guideRow}>
                  <img
                    src={ConfigureGif}
                    alt={"user guide"}
                    style={{
                      width: "180px",
                      heigth: "105px",
                    }}
                  />
                  <p>Configure ORDER</p>
                </div>
                <div className={styles.guideRow}>
                  <img
                    src={ValidateGif}
                    alt={"user guide"}
                    style={{
                      width: "180px",
                      heigth: "105px",
                    }}
                  />
                  <p>
                    Validate your selection to send it to your Paco Rabanne
                    counselor and to make it available on your Joor account
                  </p>
                </div>
              </div>
            ) : null}
            {showVideo ? (
              <div className={styles.video_wrap}>
                <VideoPlayer link={showVideo} />
              </div>
            ) : null}
          </div>
          <div className={styles.menu}>
            <button
              onClick={() => {
                setShowVideo(
                  "https://storage.googleapis.com/paco-assets/Itw_Bastien_Daguzan_v1.4.mp4"
                );
                setUserGuide(false);
              }}
            >
              Words from Bastien Daguzan
            </button>
            <button
              onClick={() => {
                setShowVideo(
                  "https://storage.googleapis.com/paco-assets/Itw_Julien_Dossena_v3.mp4"
                );
                setUserGuide(false);
              }}
            >
              Interview with Julien Dossena
            </button>

            <Link
              to={"/inspirations"}
              onClick={() => {
                globalActions.openMenu(false);
                setUserGuide(false);
              }}
            >
              Curated by
            </Link>
            <button
              onClick={() => {
                globalActions.showBestsellers(true);
                setUserGuide(false);
              }}
            >
              Best sellers
            </button>
            <button
              onClick={() => {
                setUserGuide(true);
                setShowVideo(null);
              }}
            >
              User guide
            </button>
          </div>
        </div>
        <style jsx="true" global="true">{`
          html {
            ${true ? "overflow-y: hidden" : "overflow-y: auto"}
          }
        `}</style>
      </div>
    </div>
  );
}
