import React, { useState, useEffect } from "react";
import CardSKU from "../../Components/Card/CardSKU/CardSKU";
import CardSKUQuantity from "../../Components/Card/CardSKU/CardSKUQuantity";
import CardSKURemove from "../../Components/Card/CardSKU/CardSKURemove";
import CardSKURemoveDecision from "../../Components/Card/CardSKU/CardSKURemoveDecision";
import { useDiary } from "../../Context/ContextDiary";
import { useLooks } from "../../Context/ContextLooks";
import styles from "./Diary.module.scss";
import ModalProduct from "../../Components/Modal/ModalProduct/ModalProduct";
import buttonAdd from "../../assets/button-add.svg";
import CardSKUAddToOrder from "../../Components/Card/CardSKU/CardSKUAddToOrder";

// type:  RTW | ACC

export default function DiaryAllProducts({ type, showAllSKUs }) {
  const [indCardToShowDeleteModule, setIndCardToShowDeleteModule] =
    useState(null);
  const [productsPerRow, setProductsPerRow] = useState(1);
  const [diaryByCategory, setDiaryByCategory] = useState(null);
  const [rows, setRows] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { order, basket, orderIds, productModal, diaryActions } = useDiary();
  const { allProducts } = useLooks();

  const defineRows = () => {
    const clientWidth = document.documentElement.clientWidth;

    if (clientWidth > 400) setProductsPerRow(4);
    if (clientWidth > 960) setProductsPerRow(6);
    if (clientWidth > 1200) setProductsPerRow(7);
    if (clientWidth <= 400) setProductsPerRow(2);
  };

  if (window) {
    window.onresize = function (e) {
      defineRows();
    };
  }

  useEffect(() => {
    defineRows();
    console.log("render");
    if (!isDeleteModalOpen) {
      setIndCardToShowDeleteModule(null);
    }
    let allProductsToDisplay = order;
    if (showAllSKUs) {
      allProductsToDisplay = allProducts;
    }
    let filter = allProductsToDisplay?.filter((el) => el.category === type);
    setDiaryByCategory(filter);
    setRows(makeRowsOfDiary(filter));
  }, [
    type,
    order,
    showAllSKUs,
    productModal,
    isDeleteModalOpen,
    productsPerRow,
    basket,
  ]);

  const makeRowsOfDiary = (unsorted = [], alreadySorted = []) => {
    const chunks = [...alreadySorted];

    const getChunk = unsorted?.splice(0, productsPerRow);

    chunks.push(getChunk);

    // push modal in the array
    if (
      productModal.show &&
      getChunk.find((c) => c.id === productModal.product.id)
    ) {
      chunks.push(productModal);
    }

    if (unsorted.length) {
      return makeRowsOfDiary(unsorted, chunks);
    } else {
      return chunks;
    }
  };

  const handleRemove = async (e, sku) => {
    e.stopPropagation();
    await diaryActions.removeSKU(basket.id, sku);
  };

  const productIsAlreadyAdded = (el) => {
    return order.find((p) => p.id === el.id);
  };
  return (
    <>
      {(order?.length === 0 || !order) && (
        <div style={{ margin: "1rem" }}>You didn't like any product yet.</div>
      )}
      {diaryByCategory && (
        <div className={styles.all_products_container}>
          {rows.map((row, i) => {
            if (Array.isArray(row)) {
              return (
                <div key={i} className={styles.products_row}>
                  {row.map((product, index) => {
                    // if (product?.quantity) {
                    //   // console.log(product);
                    //   product.quantity_total = 0;
                    //   Object.values(product?.quantity).map(
                    //     (size) => (product.quantity_total += size.quantity)
                    //   );
                    // }
                    if (product?.orderedImages[0].url) {
                      const orderProduct = productIsAlreadyAdded(product);

                      return (
                        <div className={styles.sku_container} key={index}>
                          <CardSKU
                            image={product.orderedImages[0]}
                            id={product.id}
                            handleClick={() => {
                              diaryActions.setModalProduct(true, product);
                            }}
                          />
                          {!showAllSKUs ||
                          (product.quantity_total &&
                            product.quantity_total > 0) ? (
                            <CardSKURemove
                              handleRemove={() => {
                                setIndCardToShowDeleteModule({
                                  row: i,
                                  col: index,
                                });
                                setIsDeleteModalOpen(true);
                              }}
                            />
                          ) : null}
                          {!showAllSKUs || orderProduct ? (
                            <CardSKUQuantity
                              quantity={orderProduct?.quantity_total || 0}
                            />
                          ) : (
                            <CardSKUAddToOrder
                              product={product}
                              iconPlus={true}
                            />
                          )}
                          {indCardToShowDeleteModule &&
                          indCardToShowDeleteModule.row === i &&
                          indCardToShowDeleteModule.col === index ? (
                            <CardSKURemoveDecision
                              lookOrProduct={"product"}
                              ok={(e) => {
                                handleRemove(e, product);
                                setIsDeleteModalOpen(false);
                              }}
                              cancel={() => {
                                setIndCardToShowDeleteModule(null);
                                setIsDeleteModalOpen(false);
                              }}
                            />
                          ) : null}
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              );
            } else {
              return (
                <ModalProduct
                  selectedProduct={
                    productIsAlreadyAdded(row.product)
                      ? productIsAlreadyAdded(row.product)
                      : row.product
                  }
                  key={i}
                />
              );
            }
          })}
        </div>
      )}
    </>
  );
}
