import React, {useState, useCallback, useEffect} from "react";
import {useDebouncedCallback} from "use-debounce";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import config from "../../../config/config";
import styles from "./ScrollTrigger.module.scss";

gsap.registerPlugin(ScrollToPlugin);

export default function ScrollTrigger({handlePlayPause}) {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [isScrolling]);

  const scrollListener = (e) => {
    const clientHeight = document.documentElement.clientHeight;
    const scrolledY = window.scrollY;
    if (scrolledY > 0 && scrolledY < clientHeight / 2 && !isScrolling) {
      handleScrollDown();
    } else if (
      scrolledY < clientHeight &&
      scrolledY > clientHeight / 2 &&
      !isScrolling
    ) {
      handleScrollUp();
    }
  };

  const debouncedUpdate = useDebouncedCallback(() => {
    if (isScrolling) {
      setIsScrolling(false);
    }
  }, config.scrollTiming);

  const handleStopScrolling = useCallback(() => {
    if (!isScrolling) {
      setIsScrolling(true);
    }
    debouncedUpdate();
  }, [isScrolling, debouncedUpdate]);

  const handleScrollDown = () => {
    gsap.to(window, {
      duration: config.scrollTiming / 1000,
      scrollTo: "#scroll-next",
    });
    handlePlayPause(false);
    handleStopScrolling();
  };

  const handleScrollUp = () => {
    gsap.to(window, {duration: config.scrollTiming / 1000, scrollTo: 0});
    handlePlayPause(true);
    handleStopScrolling();
  };

  return (
    <>
      <div id="scroll-next" />
      <button className={styles.go_back_wrap} onClick={handleScrollUp}>
        <i className={styles.arrow_up} />
        <span>go back to show</span>
      </button>
    </>
  );
}
