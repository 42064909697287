import React from "react";
import iconClose from "../../assets/iconClose.png";
import styles from "./Modal.module.scss";

export default function ModalClose({ close }) {
  return (
    <div className={styles.close_icon_container} onClick={close}>
      <img src={iconClose} alt={"close"} />
    </div>
  );
}
