import React, { useState, useRef, useEffect } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useDiary } from "../../Context/ContextDiary";
import { extractSymbol, getPriceInCurrency } from "../../utils/utils";
import logo from "../../assets/logo.png";
import PacoFont from "../../assets/fonts/Paco.otf";
import excelExport from "../../services/excelExport";
import LoggedInToolbar from "../../Components/Shared/LoggedInToolbar";
import Button from "../../Components/Button/Button";
import styles from "../Order/Order.module.scss";
import iconBack from "../../assets/iconBack.png";
import Loader from "../../Components/Shared/Loader";
import { getItemStorage } from "../../utils/localStorage";
import { Link } from "react-router-dom";

Font.register({ family: "Paco", src: PacoFont });

const pdfStyles = StyleSheet.create({
  page: {
    paddingTop: 65,
    paddingBottom: 120,
    paddingHorizontal: 20,
  },
  title: {
    textAlign: "center",
    fontSize: 32,
    marginBottom: 32,
    fontFamily: "Paco",
  },
  table: {
    display: "flex",
    flexDirection: "column",
  },
  tableRowHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  tableCellHead: {
    flex: 1,
    padding: 5,
    fontSize: 11,
    fontFamily: "Paco",
    textAlign: "center",
  },
  //   tableCellSubhead: {
  //     flex: 1,
  //     padding: 5,
  //     fontSize: 10,
  //     fontFamily: "Paco",
  //     textAlign: "center",
  //   },
  tableCellImg: {
    flex: 1,
    padding: 5,
    textAlign: "center",
  },
  itemImg: {
    width: 50,
    height: 75,
  },
  tableCell: {
    flex: 1,
    padding: 5,
    fontSize: 7,
    fontFamily: "Paco",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    position: "absolute",
    bottom: 5,
    left: 0,
    right: 0,
    height: 100,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const MyDoc = ({ order, currency }) => {
  return (
    <Document>
      <Page size="A4" wrap={true} style={pdfStyles.page}>
        <View
          render={({ pageNumber }) =>
            pageNumber > 1 && <View style={{ paddingTop: 60 }} />
          }
          fixed
        />
        <Text style={pdfStyles.title}>S22 Validated Selection</Text>
        <View style={pdfStyles.table}>
          <View style={pdfStyles.tableRowHead}>
            {[
              "",
              "sku",
              "Retail Price",
              "Wholesale Price",
              "Quantity",
              "Total wholesale",
            ].map((key, index) => {
              return (
                <Text style={pdfStyles.tableCellHead} key={index}>
                  {key}
                </Text>
              );
            })}
          </View>
          <View style={pdfStyles.tableRow}>
            {[
              "",
              "",
              currency.retail,
              currency.wholesale,
              order.reduce((acc, item) => acc + item.quantity, 0),
              order.reduce(
                (acc, item) =>
                  acc +
                  parseInt(
                    getPriceInCurrency(
                      currency.wholesale,
                      item,
                      item.quantity,
                      "wholesale"
                    ).replace(/\D/g, "")
                  ),
                0
              ) + extractSymbol(currency.wholesale),
            ].map((key, index) => {
              return (
                <Text style={pdfStyles.tableCell} key={index}>
                  {key}
                </Text>
              );
            })}
          </View>
          {order
            .sort(function (a, b) {
              return ("" + b.category).localeCompare(a.category);
            })
            .map((item, index) => {
              return (
                <View style={pdfStyles.tableRow} key={index}>
                  <Text style={pdfStyles.tableCellImg}>
                    <Image
                      style={pdfStyles.itemImg}
                      source={item.orderedImages?.[0]?.url}
                    />
                  </Text>
                  <Text style={pdfStyles.tableCell}>{item.skuId}</Text>
                  <Text style={pdfStyles.tableCell}>
                    {getPriceInCurrency(currency.retail, item, 1, "retail")}
                  </Text>
                  <Text style={pdfStyles.tableCell}>
                    {getPriceInCurrency(
                      currency.wholesale,
                      item,
                      1,
                      "wholesale"
                    )}
                  </Text>
                  <Text style={pdfStyles.tableCell}>{item.quantity}</Text>
                  <Text style={pdfStyles.tableCell}>
                    {getPriceInCurrency(
                      currency.wholesale,
                      item,
                      item.quantity,
                      "wholesale"
                    )}
                  </Text>
                </View>
              );
            })}
        </View>
        <View fixed style={pdfStyles.footer}>
          <Image source={logo} />
        </View>
      </Page>
    </Document>
  );
};

export default function ExportPDF(props) {
  const { order, currency } = useDiary();

  const [click, setClick] = useState(false);
  const [url, setUrl] = useState("");
  const downloadButtonRef = useRef();

  const handleExcelExport = async () => {
    const data = [];

    data.push([
      "id",
      "category",
      "retailPrice",
      "wholesalePrice",
      "quantity",
      "total wholesale",
    ]);
    order
      .sort(function (a, b) {
        return ("" + b.category).localeCompare(a.category);
      })
      .forEach((sku) =>
        data.push([
          sku.skuId,
          sku.category,
          getPriceInCurrency(currency.retail, sku, 1, "retail"),
          getPriceInCurrency(currency.wholesale, sku, 1, "wholesale"),
          sku.quantity,
          getPriceInCurrency(
            currency.wholesale,
            sku,
            sku.quantity,
            "wholesale"
          ),
        ])
      );

    excelExport(data);
  };

  useEffect(() => {
    if (downloadButtonRef.current && url) {
      handleExcelExport();
      downloadButtonRef.current.click();
      setClick(false);
      setUrl("");
    }
  }, [downloadButtonRef, url]);

  useEffect(() => {
    setTimeout(() => {
      setClick(true);
    }, 2000);
  }, []);

  return (
    <div className={styles.thank_you_page_container}>
      <LoggedInToolbar />
      {order && click ? (
        <div>
          <PDFDownloadLink
            document={<MyDoc order={order} currency={currency} />}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <div id="loader">
                  <Loader
                    type="MutatingDots"
                    color={"black"}
                    secondaryColor={"grey"}
                    height={150}
                    width={150}
                  />
                </div>
              ) : (
                setUrl(url)
              )
            }
          </PDFDownloadLink>
          <a
            href={url}
            download={`FW22 PACO RABANNE SELECTION ${new Date().toLocaleDateString(
              "fr-FR",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              }
            )} - ${getItemStorage("storeName")}`}
            ref={downloadButtonRef}
          >
            .
          </a>
        </div>
      ) : (
        <div
          className={styles.export_container}
          style={{ width: "100%", alignItems: "center" }}
        >
          <p>
            Your download will start shortly... If it didn't start, you can use
            the button below.
          </p>
          <div className={styles.btn_container}>
            <Button
              rounded
              color={"black"}
              action={() => setClick(true)}
              text={<div>Download Excel/PDF reports</div>}
            />
            <Link to={"/main"}>
              <Button
                rounded
                variant={"outlined"}
                color="black"
                text={
                  <>
                    <img
                      src={iconBack}
                      alt={"back icon"}
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "0.5rem",
                      }}
                    />
                    back to experience
                  </>
                }
              />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
