import React, { useEffect, useState } from "react";
import CardLookFrame from "../../Components/Card/CardLookFrame";
import { useDiary } from "../../Context/ContextDiary";
import { useGlobal } from "../../Context/ContextGlobal";
import { setItemStorage } from "../../utils/localStorage";
import styles from "./Main.module.scss";

export default function MainLookFrames({
  looks,
  videoRef,
  setCurrentLook,
  skipTo,
}) {
  const { looksLikedIds } = useDiary();
  const { showOnlyVideo, globalActions } = useGlobal();

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const chrono = setInterval(() => {
      const time = videoRef.current?.currentTime;
      if (time) setItemStorage("mainVideoTime", time.toString());
      setCurrentTime(time);
    }, 800);

    return () => {
      clearInterval(chrono);
    };
  }, []);

  useEffect(() => {
    setCurrentLook(
      looks.find(
        (el) => currentTime >= el.appearance && currentTime < el.disappearance
      )
    );
  }, [looks, currentTime, setCurrentLook]);

  const getFillRatio = (start, end) => {
    if (end <= currentTime) {
      return 1;
    } else if (currentTime > start && end > currentTime) {
      return Math.round(((currentTime - start) * 100) / (end - start)) / 100;
    } else {
      return 0;
    }
  };

  const isLiked = (id) => {
    return looksLikedIds.some((e) => e.id === id);
  };

  useEffect(() => {
    console.log(showOnlyVideo, currentTime);
    if (currentTime < 10 && !showOnlyVideo) {
      globalActions.showOnlyVideo(true);
    } else if (currentTime >= 10 && showOnlyVideo) {
      globalActions.showOnlyVideo(false);
    }
  }, [currentTime, globalActions, showOnlyVideo]);

  if (showOnlyVideo) return null;

  return (
    <div className={styles.look_frames_container}>
      {looks.map((look, i) => (
        <div key={i} onClick={() => skipTo(look)}>
          <CardLookFrame
            isLiked={isLiked(look.id)}
            lookFrame={{
              ...look,
              fillRatio: getFillRatio(
                parseInt(look.appearance),
                parseInt(look.disappearance)
              ),
            }}
          />
        </div>
      ))}
    </div>
  );
}
