import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import styles from "./Order.module.scss";
// import iconBack from "../../assets/iconBack.png";

export default function OrderTopLeftBar() {
  return (
    <div className={styles.top_left_bar_container}>
      <Link to="/main">
        <Button
          rounded
          color={"black"}
          variant={"outlined"}
          text={
            <div>
              ← Back to show
              {/* <img
                src={iconBack}
                alt={"back icon"}
                style={{ width: "1rem", height: "1rem", marginLeft: "0.5rem" }}
              /> */}
            </div>
          }
        />
      </Link>
    </div>
  );
}
