import React, {useState} from "react";
import styles from "../../Modal.module.scss";
import {ReactComponent as ArrowRightGrey} from "../../../../assets/arraw-right-grey.svg";
import {useBuyer} from "../../../../Context/ContextBuyer";

export default function Status({email}) {
  const [isResentClicked, setIsResendClicked] = useState(false);

  const {buyerActions} = useBuyer();

  const handleResendLink = async () => {
    setIsResendClicked(true);
    await buyerActions.startExperience(email);
    setTimeout(() => {
      setIsResendClicked(false);
    }, 100000);
  };

  return (
    <div className={`${styles.form_container} ${styles.status_message}`}>
      {/* <div className={styles.icon_val_container}>
        <img src={iconValidated} alt={"iconValidated"} />
      </div> */}
      <h1>APPROVE THIS LOGIN FROM YOUR EMAIL.</h1>
      {isResentClicked ? (
        <p>You can resend a link once per minute</p>
      ) : (
        <button onClick={handleResendLink}>
          Resend link <ArrowRightGrey />
        </button>
      )}
      <p>A link has been sent to {email} to authentificate you</p>

      {/* <div className={styles.button_container}>
        <Button
          variant={"outlined"}
          color={"black"}
          text={"Back to main screen"}
          action={() => handleChangeScreen()}
        />
      </div> */}
    </div>
  );
}
