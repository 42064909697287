import React from "react";
import styles from "./Order.module.scss";
import { useDiary } from "../../Context/ContextDiary";
import { extractSymbol, getPriceInCurrency } from "../../utils/utils";

export default function OrderQuantities(props) {
  const { order, currency } = useDiary();

  return (
    <>
      {order?.length === 0 && (
        <div style={{ margin: "1rem" }}>You didn't like any product yet.</div>
      )}

      <table>
        <thead>
          <tr>
            <th></th>
            <th>sku</th>
            <th>retail price</th>
            <th>wholesale price</th>
            <th>quantity</th>
            <th>total wholesale</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.subhead_cells}>
            <td></td>
            <td></td>
            <td>{currency.retail}</td>
            <td>{currency.wholesale}</td>
            <td>{order.reduce((acc, item) => acc + item.quantity_total, 0)}</td>
            <td>
              {/* Sorry in advance for this beautiful code :-) */}
              {order.reduce(
                (acc, item) =>
                  acc +
                  parseInt(
                    getPriceInCurrency(
                      currency.wholesale,
                      item,
                      item.quantity_total,
                      "wholesale"
                    ).replace(/\D/g, "")
                  ),
                0
              )}
              {extractSymbol(currency.wholesale)}
            </td>
          </tr>
          {order
            .sort(function (a, b) {
              return ("" + b.category).localeCompare(a.category);
            })
            .map((sku, index) => {
              if (sku?.orderedImages?.[0]?.url) {
                return (
                  <tr key={index}>
                    <td className={styles.td_img}>
                      <img src={sku.orderedImages?.[0]?.url} alt={sku.skuId} />
                    </td>
                    <td>
                      {sku.skuId}
                      <br />
                      <span
                        className={`${styles.tag_category} ${
                          styles[sku.category]
                        }`}
                      >
                        {sku.category}
                      </span>
                    </td>
                    <td>
                      {getPriceInCurrency(currency.retail, sku, 1, "retail")}
                    </td>
                    <td>
                      {getPriceInCurrency(
                        currency.wholesale,
                        sku,
                        1,
                        "wholesale"
                      )}
                    </td>
                    <td>
                      <div className={styles.qty_container}>
                        {/* <div
                        className={styles.qty_cta_container}
                        onClick={() =>
                          diaryActions.updateQuantity(
                            index,
                            Math.max(1, sku.quantity - 1)
                          )
                        }
                      >
                        <img src={iconMinus} alt={"minus"} />
                      </div> */}
                        <div className={styles.qty_val}>
                          <input
                            min={1}
                            type={"number"}
                            value={sku.quantity_total}
                            readOnly
                            // onChange={(e) =>
                            //   diaryActions.updateQuantity(
                            //     index,
                            //     Math.max(1, e.target.value)
                            //   )
                            // }
                          />
                        </div>
                        {/* <div
                        className={styles.qty_cta_container}
                        onClick={() =>
                          diaryActions.updateQuantity(index, sku.quantity + 1)
                        }
                      >
                        <img src={iconPlus} alt={"plus"} />
                      </div> */}
                      </div>
                    </td>
                    <td>
                      {getPriceInCurrency(
                        currency.wholesale,
                        sku,
                        sku.quantity_total,
                        "wholesale"
                      )}
                    </td>
                  </tr>
                );
              }
              return null;
            })}
        </tbody>
      </table>
    </>
  );
}
