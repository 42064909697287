import React, { useReducer, useContext, useMemo, useEffect } from "react";
import api from "../services/api";
import {
  getItemStorage,
  removeItemStorage,
  setItemStorage,
} from "../utils/localStorage";
import { useDiary } from "./ContextDiary";

const Buyer = React.createContext();

const initialState = {
  isLoading: true,
  buyer: null,
  disguise: null,
  error: null,
  statusMessage: null,
  buyerForCreation: null,
  isNewLogIn: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_BUYER":
      return Object.assign({}, state, {
        ...state,
        buyer: action.buyer,
        isLoading: false,
      });
    case "SET_BUYER_FOR_CREATION":
      return Object.assign({}, state, {
        ...state,
        buyerForCreation: action.buyer,
      });
    case "SET_BUYER_CAN_PROCEED":
      return Object.assign({}, state, {
        ...state,
        buyer: {
          ...state.buyer,
          isLoading: false,
        },
      });
    case "SET_ACCESS_REQUESTED":
      return Object.assign({}, state, {
        ...state,
        accessRequested: true,
      });
    case "SET_NEW_LOGIN":
      return Object.assign({}, state, {
        ...state,
        isNewLogIn: action.isNewLogIn,
      });
    case "SET_ERROR":
      return Object.assign({}, state, {
        ...state,
        error: action.error,
        isLoading: false,
      });
    case "SET_STATUS_MESSAGE":
      return Object.assign({}, state, {
        ...state,
        statusMessage: action.message,
        isLoading: false,
      });
    case "SET_LOADING":
      return Object.assign({}, state, {
        ...state,
        isLoading: action.isLoading,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const BuyerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { diaryActions } = useDiary();

  const cleanStatuses = () => {
    dispatch({
      type: "SET_ERROR",
      error: null,
    });

    dispatch({
      type: "SET_STATUS_MESSAGE",
      message: null,
    });
  };

  const actions = useMemo(
    () => ({
      startExperience: async (email) => {
        // console.log(email);
        cleanStatuses();
        let res = await api.auth.startExperience({
          email,
        });

        if (res.statusCode && res.statusCode !== 200) {
          dispatch({
            type: "SET_ERROR",
            error: res.message,
          });
        } else {
          dispatch({
            type: "SET_BUYER_FOR_CREATION",
            buyer: res,
          });
        }

        return res.statusCode && res.statusCode !== 200
          ? { ok: false }
          : { ok: true, data: res };
      },
      requestAccess: async (data) => {
        cleanStatuses();

        let res = await api.auth.requestAccess(data);
        if (res.statusCode && res.statusCode !== 200) {
          dispatch({
            type: "SET_ERROR",
            error: res.message,
          });
        } else {
          dispatch({
            type: "SET_BUYER_FOR_CREATION",
            buyer: res,
          });

          dispatch({
            type: "SET_STATUS_MESSAGE",
            message:
              "Request for access has been sent. You will be able to login after your request is accepted",
          });
        }

        return res.statusCode && res.statusCode !== 201 ? false : true;
      },
      createAccount: async (data) => {
        cleanStatuses();

        let res = await api.auth.createAccount(data);
        if (res.statusCode && res.statusCode !== 200) {
          dispatch({
            type: "SET_ERROR",
            error: res.message,
          });
        } else {
          dispatch({
            type: "SET_BUYER_FOR_CREATION",
            buyer: res,
          });
        }

        return res.statusCode && res.statusCode !== 201
          ? { ok: false }
          : { ok: true, data: res };
      },
      updateAccount: async (data) => {
        cleanStatuses();

        let res = await api.auth.updateAccount(data);
        if (res.statusCode && res.statusCode !== 200) {
          dispatch({
            type: "SET_ERROR",
            error: res.message,
          });
        } else {
          dispatch({
            type: "SET_NEW_LOGIN",
            isNewLogIn: true,
          });

          setItemStorage("buyerId", res.id);
          setItemStorage("firstname", res.firstname);
          setItemStorage("lastname", res.lastname);
          setItemStorage("storeName", res.storeName);
          setItemStorage("country", res.country);
          dispatch({
            type: "SET_BUYER",
            buyer: res,
          });
          initBuyer(res.id);
        }

        return res.statusCode && res.statusCode !== 201 ? false : true;
      },
      getReteilersBySellerId: async () => {
        console.log(state);
        let res = await api.retailers.getReteilersBySellerId(state.buyer.id);
        return res && res.statusCode && res.statusCode !== 201 ? false : true;
      },
      applyDisguise: async (buyerEmail) => {
        cleanStatuses();

        if (buyerEmail) {
          let res = await api.auth.applyDisguise(buyerEmail);
          if (res.statusCode && res.statusCode !== 200) {
            dispatch({
              type: "SET_ERROR",
              error: res.message,
            });
          } else {
            initBuyer(res.id);
          }
          return res.statusCode && res.statusCode !== 200
            ? { ok: false }
            : { ok: true };
        } else {
          initBuyer(getItemStorage("buyerId"));
        }
      },
      checkMagicLink: async (link) => {
        try {
          let res = await api.auth.checkMagicLink(link);
          // console.log(res);

          if (res.statusCode && res.statusCode !== 200) {
            dispatch({
              type: "SET_ERROR",
              error: res.message,
            });
            return { ok: false };
          } else {
            dispatch({
              type: "SET_NEW_LOGIN",
              isNewLogIn: true,
            });

            dispatch({
              type: "SET_BUYER",
              buyer: res,
            });
            return { ok: true };
          }
        } catch (error) {
          console.log(error);
        }
      },
      logout: () => {
        removeItemStorage("buyerId");
        dispatch({ type: "SET_BUYER", buyer: null });
      },
      changeNewLogin: () => {
        dispatch({ type: "SET_NEW_LOGIN", isNewLogIn: false });
      },
    }),
    [state]
  );

  const initBuyer = async (id) => {
    let res = await api.retailers.getRetailer(id);

    if (res) {
      const buyerData = res.buyers[0];

      const looksLikedIds = buyerData.looksLiked;

      dispatch({
        type: "SET_BUYER",
        buyer: buyerData,
      });

      diaryActions.initDiary(looksLikedIds, buyerData);
    }
  };

  useEffect(() => {
    if (getItemStorage("buyerId")) {
      initBuyer(getItemStorage("buyerId"));
    } else {
      dispatch({ type: "SET_LOADING", isLoading: false });
    }
  }, []);

  const stateToReturn = {
    buyerForCreation: state.buyerForCreation,
    buyer: state.buyer,
    isDisguise: getItemStorage("buyerId") !== state.buyer?.id,
    isNewLogIn: state.isNewLogIn,
    error: state.error,
    seller: state.buyer?.seller?.length ? state.buyer?.seller[0] : null,
    isLoading: state.isLoading,
    accessRequested: state.accessRequested,
    statusMessage: state.statusMessage,
    buyerActions: actions,
  };

  console.log("BUYER STATE", stateToReturn);

  return <Buyer.Provider value={stateToReturn}>{children}</Buyer.Provider>;
};

export const useBuyer = () => useContext(Buyer);
