import React, { useEffect, useState } from "react";
import styles from "./Main.module.scss";
import iconSoundNone from "../../assets/iconSoundNone.png";
import iconSound from "../../assets/iconSound.png";
import { Range } from "react-range";
import ReactSlider from "react-slider";

export default function MainVolumeControl({ showOnlyVideo, videoRef }) {
  const [isMute, setIsMute] = useState(true);
  // const [volume, setVolume] = useState(1);
  // const [showSlider, setShowSlider] = useState(false);

  const toggleMute = () => {
    if (videoRef && videoRef.current) videoRef.current.muted = !isMute;
    setIsMute(!isMute);
  };

  useEffect(() => {
    setTimeout(() => {
      toggleMute();
    }, 500);
  }, []);

  if (showOnlyVideo) return <></>;

  return (
    //
    <div className={styles.volume_control_container} onClick={toggleMute}>
      {/* {showSlider && (
        <div> */}
      {/* <ReactSlider
            orientation="vertical"
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onBeforeChange={(value, index) =>
              console.log(`onBeforeChange: ${JSON.stringify({ value, index })}`)
            }
            onChange={(value, index) =>
              console.log(`onChange: ${JSON.stringify({ value, index })}`)
            }
            onAfterChange={(value, index) =>
              console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
            }
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow}</div>
            )}
          /> */}
      {/* <input
            type="range"
            min={0}
            max={1}
            step={0.02}
            value={volume}
            onChange={(event) => {
              setVolume(event.target.valueAsNumber);
            }}
            onBlur={() => {
              setShowSlider(false);
            }}
            style={{
              width: 8,
              height: 120,
              appearance: "slider-vertical",
              backgroundColor: "black",
              borderColor: "black",
            }}
          /> */}
      {/* </div>
      )} */}
      <img
        src={isMute ? iconSoundNone : iconSound}
        alt={"no sound"}
        // onClick={() => setShowSlider(true)}
      />
    </div>
  );
}
