import React, {useRef} from "react";
import ModalLogin from "../../Components/Modal/ModalLogin/ModalLogin";
import WelcomeVideo from "./WelcomeVideo";
import styles from "./Welcome.module.scss";
import {useBuyer} from "../../Context/ContextBuyer";
import {CSSTransition} from "react-transition-group";
import {useHistory} from "react-router-dom";

export default function Welcome() {
  const {buyer, error, statusMessage} = useBuyer();
  const history = useHistory();

  const videoRef = useRef(null);

  return (
    <div className={styles.page_container}>
      <WelcomeVideo videoRef={videoRef} />
      <CSSTransition
        in={
          !buyer ||
          history.location.pathname === "/start-experience" ||
          history.location.pathname === "/invitation" ||
          history.location.pathname === "/disguise"
        }
        unmountOnExit
        timeout={300}
        classNames="transition-modal-login"
      >
        <>
          <ModalLogin error={error} statusMessage={statusMessage} />
        </>
      </CSSTransition>
    </div>
  );
}
