import React from "react";
import styles from "../Card.module.scss";

export default function CardSKURemoveDecision({ lookOrProduct, ok, cancel }) {
  return (
    <div className={styles.remove_decision_container}>
      <div className={styles.blured_layout}></div>
      <div className={styles.decision_modal}>
        <span>
          This action will delete this {lookOrProduct} from your selection. Do
          you want to continue?
        </span>
        <div className={styles.decision_modal_actions}>
          <button onClick={cancel}>Cancel</button>
          <button onClick={ok}>Ok</button>
        </div>
      </div>
    </div>
  );
}
