import React from "react";
import styles from "../Modal.module.scss";

export default function ModalDecision({ message, ok, cancel }) {
  return (
    <div className={styles.modal_decision_wrap}>
      <div className={styles.blured_layout}></div>
      <div className={styles.decision_modal}>
        <span>{message}</span>
        <div className={styles.decision_modal_actions}>
          <button onClick={cancel} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={ok} className={styles.ok}>
            Continue
          </button>
        </div>
      </div>
      <style jsx="true" global="true">{`
        html {
          overflow-y: hidden;
        }
      `}</style>
    </div>
  );
}
