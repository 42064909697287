import styles from "./OrderSideBar.module.scss";
import iconClose from "../../../assets//iconClose.png";
import { spaceEachThreeNumbers } from "../../../utils/utils";
import { useRef, useEffect } from "react";
import gsap from "gsap";
import { useGlobal } from "../../../Context/ContextGlobal";
import { useDiary } from "../../../Context/ContextDiary";
import { useState } from "react";
import api from "../../../services/api";
import { useBuyer } from "../../../Context/ContextBuyer";
import Loader from "../../../Components/Shared/Loader";
import ModalDecision from "../../../Components/Modal/ModalDecision/ModalDecision";

const OrderSideBar = () => {
  const [res, setRes] = useState(null);
  // const [skuQuantity, setSkuQuantity] = useState(0);
  const [skuNumber, setSkuNumber] = useState(0);
  const { openOrderValidation, globalActions } = useGlobal();

  const [showWarning, setShowWarning] = useState(false);

  const { order, basket } = useDiary();
  const { buyer } = useBuyer();

  const mask = useRef(null);
  const container = useRef(null);
  const cross = useRef(null);

  const close = () => {
    if (openOrderValidation) {
      setRes(null);
      // setSkuQuantity(0);
      setSkuNumber(0);
      globalActions.openOrderValidation(false);
      gsap.to(container.current, {
        x: "100vw",
        duration: 0.7,
      });
      gsap.to(mask.current, { duration: 0.7, opacity: 0 });
      gsap.to(mask.current, { display: "none" });
      gsap.to(cross.current, {
        x: "100vw",
        duration: 0.7,
      });
    }
  };

  useEffect(() => {
    const createOrder = async () => {
      const res = await api.orders.createJoorOrder({
        email: buyer.mail,
      });
      console.log(res.data[0]);
      setRes(res.data[0]);
    };

    if (openOrderValidation) {
      order.map((item) => {
        if (item.quantity) {
          setSkuNumber((prevSkuNumber) => {
            return prevSkuNumber + 1;
          });
          // Object.entries(item.quantity).map((size) => {
          //   console.log("sku quantity : ", size[1].quantity);
          //   setSkuQuantity((prevQuantity) => {
          //     return prevQuantity + size[1].quantity;
          //   });
          // });
        }
        return null;
      });

      gsap.to(cross.current, { display: "block" });
      gsap.to(mask.current, { display: "block" });
      gsap.to(mask.current, { duration: 0.7, opacity: 1 });
      gsap.to(container.current, {
        x: "0",
        duration: 0.7,
      });
      gsap.to(cross.current, {
        x: "0",
        duration: 0.7,
      });

      console.log(basket);

      if (
        basket.order.map((el) => el.quantity_total).includes(0) ||
        basket.order.map((el) => el.quantity_total).includes(undefined)
      ) {
        setShowWarning(true);
      } else {
        createOrder();
      }
    }
  }, [order, openOrderValidation, buyer.mail]);

  return (
    <>
      {showWarning ? (
        <ModalDecision
          ok={() => {
            setShowWarning(false);
            close();
          }}
          cancel={() => {
            setShowWarning(false);
            close();
          }}
          message="Some items do not have quantities. Please add quantities to your order or delete them from your selection."
        />
      ) : null}
      <div className={styles.orderSideBarMask} ref={mask}></div>
      <div className={styles.orderSideBarContainer} ref={container}>
        <div className={styles.OSBContent}>
          {!res && (
            <div className={styles.loaderContainer}>
              <Loader
                type="MutatingDots"
                color={"black"}
                secondaryColor={"grey"}
                height={150}
                width={150}
              />
            </div>
          )}
          {res && (
            <div className={styles.OSBTitles}>
              <h1 className={styles.OSBTitle}>FW22 VALIDATED SELECTION</h1>
              <p className={styles.OSBSubTitle}>
                Your order has been sent to JOOR.
              </p>
              <p className={styles.OSBSubTitle}>
                You can update your selection by changing quantities.
              </p>

              <p className={styles.OSBThankYou}>Thank you!</p>
              <p className={styles.OSBOrderID}>PO{res.po_number}</p>
            </div>
          )}
          {res &&
            order.map((d, index) => {
              if (d.quantity !== null) {
                console.log(d);

                let retailPrice = 0;
                let wholesalePrice = 0;

                if (d.retailPrice) {
                  retailPrice = d.retailPrice;
                } else {
                  retailPrice = Object.values(d.quantity)[0]?.retail;
                }

                if (d.wholesalePrice) {
                  wholesalePrice = d.wholesalePrice;
                } else {
                  wholesalePrice = Object.values(d.quantity)[0]?.wholesale;
                }

                return (
                  <OrderSideBarProductRow
                    key={index}
                    sku={d.productSkuId}
                    retailPrice={retailPrice}
                    wholeSalePrice={wholesalePrice}
                    quantity={d.quantity_total}
                    photoSrc={d.orderedImages[0].url}
                    currency={res.price_type.currency}
                    retailCurrency={res.price_type.currency_retail}
                  />
                );
              } else {
                return null;
              }
            })}

          {res && (
            <ValidatedSelectionTotal
              discountApplied={buyer.discountAmount || 0}
              grandTotal={res.total - res.discount.discount.toFixed(2)}
              skuNumber={skuNumber}
              subTotalWholeSale={res.total}
              totalQuantity={order.reduce(
                (acc, el) => acc + el.quantity_total,
                0
              )}
              currency={res.price_type.currency}
            />
          )}
        </div>
      </div>
      <img
        ref={cross}
        src={iconClose}
        alt="Close"
        onClick={() => close()}
        className={styles.orderSideBarClose}
      />
    </>
  );
};

export default OrderSideBar;

const OrderSideBarProductRow = ({
  sku,
  retailPrice,
  wholeSalePrice,
  quantity,
  photoSrc,
  currency,
  retailCurrency,
}) => {
  return (
    <div className={styles.OrderSideBarProductRowContainer}>
      <hr className={styles.OSBPRHr} />
      <div className={styles.OSBPRContentCol}>
        <img
          src={photoSrc}
          alt={`${sku}Thumbnail`}
          className={styles.OSBPRPhoto}
        />
        <div className={styles.OSBPRProperties}>
          <div className={styles.OSBPRPropertiesTitles}>
            <p className={styles.OSBPRPropertesTitleSku}>sku {sku}</p>
            <p className={styles.OSBPRPropertesTitle}>Retail Price</p>
            <p className={styles.OSBPRPropertesTitle}>quantity</p>
            <p className={styles.OSBPRPropertesTitle}>Wholesale price</p>
            <p className={styles.OSBPRPropertesTitleTotal}>total wholesale</p>
          </div>
          <div className={styles.OSBPRPropertiesValues}>
            <img
              src={iconClose}
              alt="decoration"
              className={styles.OSBPRDeleteProduct}
            />
            <p className={styles.OSBPRPropertesValue}>
              {spaceEachThreeNumbers(retailPrice)} {retailCurrency}
            </p>
            <p className={styles.OSBPRPropertesValue}>
              {spaceEachThreeNumbers(quantity)}
            </p>
            <p className={styles.OSBPRPropertesValue}>
              {spaceEachThreeNumbers(wholeSalePrice)} {currency}
            </p>
            <p className={styles.OSBPRPropertesValueTotal}>
              {spaceEachThreeNumbers(wholeSalePrice * quantity)} {currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ValidatedSelectionTotal = ({
  skuNumber,
  totalQuantity,
  subTotalWholeSale,
  discountApplied,
  grandTotal,
  currency,
}) => {
  return (
    <div className={styles.validatedSelectionTotalContainer}>
      <hr className={styles.VSTHr} />
      <div className={styles.VSTRow}>
        <p className={styles.VSTTitle}>NUMBER OF SKU</p>
        <p className={styles.VSTValue}>{spaceEachThreeNumbers(skuNumber)}</p>
      </div>
      <div className={styles.VSTRow}>
        <p className={styles.VSTTitle}>TOTAL QUANTITY</p>
        <p className={styles.VSTValue}>
          {spaceEachThreeNumbers(totalQuantity)}
        </p>
      </div>
      <div className={styles.VSTRow}>
        <p className={styles.VSTTitle}>SUBTOTAL WHOLESALE</p>
        <p className={styles.VSTValue}>
          {spaceEachThreeNumbers(subTotalWholeSale)} {currency}
        </p>
      </div>
      <div className={styles.VSTRow}>
        <p className={styles.VSTTitle}>DISCOUNT APPLIED</p>
        <p className={styles.VSTValue}>{discountApplied} %</p>
      </div>
      <div className={styles.VSTRow}>
        <p className={styles.VSTTitle}>TOTAL</p>
        <p className={styles.VSTValue}>
          {spaceEachThreeNumbers(grandTotal)} {currency}
        </p>
      </div>
      <hr className={styles.VSTHr} />
    </div>
  );
};
