import React, { useReducer, useContext, useMemo } from "react";
import api from "../services/api";

const Inspirations = React.createContext();

const initialState = {
  inspirations: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INSPIRATIONS":
      return Object.assign({}, state, {
        ...state,
        inspirations: action.inspirations,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const InspirationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  console.log('INSPIRATIONS STATE', state)

  const actions = useMemo(
    () => ({
      fetchInspirations: async () => {
        const res = await api.inspirations.getInspirations();
        if (res?.inspirations)
          dispatch({
            type: "SET_INSPIRATIONS",
            inspirations: res.inspirations,
          });
      },
    }),
    []
  );

  return (
    <Inspirations.Provider
      value={{
        inspirations: state.inspirations,
        inspirationsActions: actions,
      }}
    >
      {children}
    </Inspirations.Provider>
  );
};

export const useInspirations = () => useContext(Inspirations);
