import React, { useState } from "react";
import styles from "./Contact.module.scss";
import iconMail from "../../assets/iconMail.png";
import { useBuyer } from "../../Context/ContextBuyer";
import ModalContact from "../Modal/ModalContact/ModalContact";

export default function Contact({ dark }) {
  const { seller } = useBuyer();
  const [extend, setExtend] = useState(false);

  return (
    <div className={`${dark ? styles.dark : ""}`}>
      <div className={styles.icon_container}>
        <img src={iconMail} alt={"phone"} onClick={() => setExtend(!extend)} />
      </div>
      {extend && (
        <ModalContact seller={seller} close={() => setExtend(false)} />
      )}
    </div>
  );
}
