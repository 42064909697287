import React, { useState } from "react";
import { useDiary } from "../../Context/ContextDiary";
import styles from "./Diary.module.scss";
import DiaryAllProducts from "./DiaryAllProducts";
import DiaryLooksLiked from "./DiaryLooksLiked";
import { useBuyer } from "../../Context/ContextBuyer";
import { ReactComponent as IconRight } from "../../assets/right-white.svg";
import { useGlobal } from "../../Context/ContextGlobal";
import { getCurrencySymbol } from "../../services/utils";
import ModalDecision from "../../Components/Modal/ModalDecision/ModalDecision";

export default function DiaryContainer() {
  const { order, retailTotal, wholesaleTotal, diaryActions, looksLikedIds } =
    useDiary();
  const [viewBy, setViewBy] = useState("RTW"); // looks | ACC | RTW
  const [showAllSKUs, setShowAllSKUs] = useState(false);
  const [showBestSellers, setShowBestsellers] = useState(false);

  const [showEmptyBasket, setShowEmptyBasket] = useState(false);

  const { buyer } = useBuyer();
  const { globalActions } = useGlobal();

  const quantityDiaryByCategory = order?.filter(
    (el) => el.category === viewBy
  ).length;

  const calculateQuantity = () => {
    let total = 0;
    if (order) {
      Object.values(order).forEach((sku) => {
        if (sku.quantity && sku.category === viewBy) {
          Object.values(sku.quantity).forEach((s) => {
            if (s.quantity) {
              total += s.quantity;
            }
          });
        }
      });
    }
    return total;
  };

  const handleCheckoutProductCard = () => {
    setViewBy("RTW");
  };

  const handleEmptyBasket = () => {
    diaryActions.emptyBasket();
    setShowEmptyBasket(false);
  };

  return (
    <div className={styles.dairy_container}>
      {showEmptyBasket ? (
        <ModalDecision
          ok={handleEmptyBasket}
          cancel={() => setShowEmptyBasket(false)}
          message="This action will cancel your order. Do you want to continue?"
        />
      ) : null}

      <div className={styles.nav}>
        <button
          onClick={() => setViewBy("RTW")}
          className={
            viewBy === "RTW" ? styles.nav_button_active : styles.nav_button
          }
        >
          ready-to-wear
        </button>

        <button
          onClick={() => setViewBy("ACC")}
          className={
            viewBy === "ACC" ? styles.nav_button_active : styles.nav_button
          }
        >
          accessories
        </button>
        <button
          onClick={() => setViewBy("looks")}
          className={
            viewBy === "looks" ? styles.nav_button_active : styles.nav_button
          }
        >
          shop by look
        </button>
        <div className={styles.nav_purchase}>
          <span>
            TOTAL WHOLESALE: {wholesaleTotal}{" "}
            {getCurrencySymbol(buyer.wholesaleCurrency)}
          </span>
          <span>
            TOTAL RETAIL: {retailTotal}{" "}
            {getCurrencySymbol(buyer.retailCurrency)}
          </span>
          {buyer.discountAmount && (
            <span>DISCOUNT APPLIED : {buyer.discountAmount}%</span>
          )}
        </div>
        {/* <Link to="/order" className={styles.nav_button_order}> */}
        <button
          className={`${styles.nav_button_order} ${
            wholesaleTotal === 0 ? styles.disabled : ""
          }`}
          onClick={() => {
            if (wholesaleTotal > 0) {
              globalActions.openOrderValidation(true);
            }
          }}
        >
          <span>check out</span> <IconRight />
        </button>
        {/* </Link> */}
      </div>
      <div className={styles.subnav}>
        <div>
          <button
            className={
              showAllSKUs || showBestSellers
                ? styles.subnav_button
                : styles.subnav_button_active
            }
            onClick={() => {
              setShowAllSKUs(false);
              setShowBestsellers(false);
            }}
          >
            your selection
          </button>
          <button
            className={
              showAllSKUs ? styles.subnav_button_active : styles.subnav_button
            }
            onClick={() => {
              setShowAllSKUs(true);
              setShowBestsellers(false);
            }}
          >
            {viewBy === "looks" ? "show" : "all sku"}
          </button>
          {viewBy === "looks" ? (
            <button
              className={
                showBestSellers
                  ? styles.subnav_button_active
                  : styles.subnav_button
              }
              onClick={() => {
                setShowAllSKUs(false);
                setShowBestsellers(true);
              }}
            >
              best sellers
            </button>
          ) : null}
        </div>
        <button
          className={[styles.subnav_button, styles.subnav_empty_basket].join(
            " "
          )}
          onClick={() => setShowEmptyBasket(true)}
        >
          EMPTY BASKET
        </button>
        {/* <button className={styles.subnav_button}>carry over</button> */}
      </div>

      <div className={styles.diary_body}>
        <div className={styles.selection_info_wrap}>
          {(viewBy === "RTW" || viewBy === "ACC") && (
            <span>
              You selected {calculateQuantity()} units of{" "}
              {quantityDiaryByCategory} differents type of SKU.
            </span>
          )}
          {viewBy === "looks" && (
            <span>You selected {looksLikedIds?.length || 0} looks.</span>
          )}
        </div>

        {(viewBy === "RTW" || viewBy === "ACC") && (
          <DiaryAllProducts type={viewBy} showAllSKUs={showAllSKUs} />
        )}
        {viewBy === "looks" && (
          <DiaryLooksLiked
            showAllSKUs={showAllSKUs}
            showBestSellers={showBestSellers}
            handleCheckoutProductCard={handleCheckoutProductCard}
          />
        )}
      </div>
    </div>
  );
}
