import React, { useState, useEffect, useRef } from "react";
import { useLooks } from "../../../Context/ContextLooks";
import { useDiary } from "../../../Context/ContextDiary";
import { useGlobal } from "../../../Context/ContextGlobal";
import styles from "../Modal.module.scss";
import ModalLook from "../ModalLook/ModalLook";
import { ReactComponent as ShopBag } from "../../../assets/Union.svg";
import Logo from "../../Logo/Logo";
import { ReactComponent as Close } from "../../../assets/button-remove.svg";
import ModalNavControls from "../ModalLook/ModalNavControls";
import { gsap } from "gsap";
import config from "../../../config/config";

export default function ModalBestSellers({ look, close }) {
  const { bestSellers } = useLooks();
  const { order } = useDiary();

  const { showBestsellers, globalActions } = useGlobal();

  const [clickedLook, setClickedLook] = useState(null);
  const [indexFirst, setIndexFirst] = useState(0);

  const bestSellersContRef = useRef(null);

  useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  useEffect(() => {
    if (showBestsellers) {
      gsap.to(bestSellersContRef.current, {
        display: "block",
      });
      gsap.fromTo(
        bestSellersContRef.current,
        { marginTop: "-100%" },
        { duration: 0.7, marginTop: 0 }
      );
    }
  }, [showBestsellers]);

  const handleCloseModal = () => {
    const tl = gsap.timeline();
    setTimeout(() => globalActions.showBestsellers(false), 700);
    tl.to(bestSellersContRef.current, { duration: 0.7, marginTop: "-100%" });
    tl.to(bestSellersContRef.current, {
      display: "none",
    });
    tl.add(() => {
      setIndexFirst(0);
    });
  };

  const shift = (isPrevious) => {
    if (isPrevious) {
      setIndexFirst(Math.max(0, indexFirst - 4));
    } else {
      setIndexFirst(Math.min(bestSellers.length - 4, indexFirst + 4));
    }
  };

  const handleClickPreviousLook = () => {
    let lookIndex = bestSellers.findIndex((el) => el.id === clickedLook?.id);
    if (lookIndex !== -1 && lookIndex > 1) {
      setClickedLook(bestSellers[lookIndex - 1]);
    }
  };

  const handleClickNextLook = () => {
    let lookIndex = bestSellers.findIndex((el) => el.id === clickedLook?.id);
    if (lookIndex !== -1 && lookIndex < bestSellers.length - 1) {
      setClickedLook(bestSellers[lookIndex + 1]);
    }
  };

  const handleScrollDown = () => {
    handleCloseModal();
    gsap.to(window, {
      duration: config.scrollTiming / 1000,
      scrollTo: "#scroll-next",
    });
  };

  if (!showBestsellers) return <></>;

  return (
    <div className={`${styles.modal_best_sellers}`} ref={bestSellersContRef}>
      <div className={styles.navmenu_row}>
        <Logo dark />
        <h2>best sellers</h2>
        <div className={styles.navmenu_actions}>
          <div className={styles.shop_bag_wrap} onClick={handleScrollDown}>
            <ShopBag style={{ filter: "invert(100%)" }} />
            <span>{order?.length || 0}</span>
          </div>
          <Close
            style={{ filter: "invert(100%)", cursor: "pointer" }}
            onClick={close}
          />
        </div>
      </div>

      <div className={styles.grid_best_sellers}>
        <ModalNavControls
          isFirst={indexFirst === 0}
          isLast={indexFirst >= bestSellers.length - 4}
          handleClickPreviousLook={() => shift(true)}
          handleClickNextLook={() => shift(false)}
        />
        {bestSellers
          .filter((e, i) => i >= indexFirst && i < indexFirst + 4)
          .map((look, index) => {
            if (look?.image[0].url) {
              return (
                <div
                  key={index}
                  className={styles.grid_item}
                  onClick={() => {
                    setClickedLook(look);
                    console.log(look);
                  }}
                >
                  <img src={look.image[0].url} alt={`look${index}`} />
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
      {clickedLook && (
        <div>
          <ModalLook
            fw={true}
            bestsellers={true}
            look={clickedLook}
            handleClickPreviousLook={handleClickPreviousLook}
            handleClickNextLook={handleClickNextLook}
            close={() => setClickedLook(null)}
          />
        </div>
      )}
      <style jsx="true" global="true">{`
        html {
          ${true ? "overflow-y: hidden" : "overflow-y: auto"}
        }
      `}</style>
    </div>
  );
}
