import config from "../config/config";

const apiUrl = config.apiUrl;

const api = {
  looks: {
    getLooks: async () => {
      try {
        const res = await fetch(`${apiUrl}/looks`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  retailers: {
    getAllRetailers: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/baskets`, {
          method: "GET",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    getRetailer: async (id) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    getReteilersBySellerId: async (id) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/sellers/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    logIn: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/mail`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    createRetailer: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/retailers`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    createRetailerAuth: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/authorize`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(" return : ", res);
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    updateRetailer: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/retailers/${body.id}`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    updateRetailerFavLook: async (body) => {
      console.log(body);
      try {
        const res = await fetch(
          `${apiUrl}/retailers/${body.buyerId}/favLooks`,
          {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  products: {
    getProductRecommendation: async (id) => {
      try {
        const res = await fetch(`${apiUrl}/products/${id}/recommended`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    // TODO: not in use
    getProductSkus: async (id) => {
      try {
        const res = await fetch(`${apiUrl}/products/${id}/skus`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    getProductById: async (id) => {
      try {
        const res = await fetch(`${apiUrl}/products/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  orders: {
    getOrder: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/orders/${body.email}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    createOrder: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/orders`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    createJoorOrder: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/orders/joor`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    getAllOrders: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/baskets`, {
          method: "GET",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  inspirations: {
    getInspirations: async () => {
      try {
        const res = await fetch(`${apiUrl}/inspirations`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  baskets: {
    emptyBasket: async (backetId) => {
      try {
        const res = await fetch(`${apiUrl}/baskets/${backetId}/empty`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    getBasket: async (email, noJoorOverwrite) => {
      try {
        const res = await fetch(
          `${apiUrl}/baskets/users/${email}${
            noJoorOverwrite ? "?noJoorOverwrite=true" : ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
    updateBasket: async (idBasket, body) => {
      try {
        const res = await fetch(`${apiUrl}/baskets/${idBasket}`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return res && res.ok ? await res.json() : false;
      } catch (err) {
        console.error(err);
      }
    },
  },
  auth: {
    startExperience: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/start`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    checkCreateAccount: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/account/${body.email}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    createAccount: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/account`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    updateAccount: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/account`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    requestAccess: async (body) => {
      try {
        const res = await fetch(`${apiUrl}/account/access`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    applyDisguise: async (emailBuyer) => {
      try {
        const res = await fetch(`${apiUrl}/account/${emailBuyer}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    checkMagicLink: async (link) => {
      try {
        const res = await fetch(`${apiUrl}/account/link`, {
          method: "POST",
          body: JSON.stringify({ token: link }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
export default api;
