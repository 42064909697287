import React from "react";
import styles from "./Inspirations.module.scss";
import ReactMarkdown from "react-markdown";

export default function InspirationsHeader({ inspiration }) {
  if (!inspiration) return null;
  return (
    <>
      <h2>Wishlist curated by {inspiration.curatorName}</h2>
      <span>{inspiration.curatorJob}</span>
      <ReactMarkdown className={styles.statement}>
        {inspiration.statement}
      </ReactMarkdown>
    </>
  );
}
