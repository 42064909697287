import React from "react";
import ModalClose from "../ModalClose";
import styles from "../Modal.module.scss";

export default function ModalContact({ close, seller }) {
  return (
    <div className={styles.modal_wrapper} onClick={close}>
      <div
        className={`${styles.modal} ${styles.modal_contact}`}
        onClick={(e) => e.stopPropagation()}
      >
        <ModalClose close={close} />
        <div className={styles.display_info_container}>
          <span className={styles.phone_number}>{seller?.name}</span>
          <span className={styles.phone_number}>{seller?.phone}</span>
          <span className={styles.phone_number}>{seller?.mail}</span>
        </div>
      </div>
    </div>
  );
}
