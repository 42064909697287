import React from "react";
import styles from "./Button.module.scss";

export default function Button({
  color = "white",
  variant = "fill",
  size = "medium",
  text = "",
  action,
  fake = false,
  noPadding = false,
  rounded = false,
  uppercase = false,
}) {
  return (
    <div
      className={`${styles[variant]} ${styles[size]} ${styles[color]} 
      ${fake ? styles.fake : ""} ${noPadding ? styles.noPadding : ""} ${
        uppercase ? styles.uppercase : ""
      } ${rounded ? styles.rounded : ""}`}
      onClick={action}
    >
      {text}
    </div>
  );
}
