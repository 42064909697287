import React from "react";
import CardLookLiked from "../../Components/Card/CardLookLiked/CardLookLiked";
import { useDiary } from "../../Context/ContextDiary";
import styles from "./Inspirations.module.scss";

export default function InspirationsDiary({ diarySkus }) {
  const { looksLikedIds } = useDiary();

  // const toggleLike = (e, sku) => {
  //   e.stopPropagation();
  //   if (isLiked(sku.id)) diaryActions.removeSKU(sku);
  //   else diaryActions.addSKU(sku);
  // };

  // const isLiked = (id) => {
  //   return orderIds.includes(id);
  // };

  if (!diarySkus) return null;

  const looksLiked = looksLikedIds.map((el) => el.id);

  const canLike = (l) => {
    return !looksLikedIds.map((el) => el.id).includes(l.id);
  };

  return (
    <div className={styles.inspiration_diary_container}>
      {diarySkus.map((look, index) => {
        return (
          <div
            className={styles.sku_container}
            key={index}
            // onClick={() => diaryActions.setModalProduct(true, sku)}
          >
            <CardLookLiked
              isModalLook={false}
              look={look}
              canAddToLiked={canLike(look)}
              inspiration={true}
            />
            {/* <CardSKULike
              isLiked={isLiked(sku.id)}
              toggleLike={(e) => toggleLike(e, sku)}
            /> */}
          </div>
        );
      })}
    </div>
  );
}
