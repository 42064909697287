import React from "react";
import styles from "../../Modal.module.scss";
import { ReactComponent as ArrowRightWhite } from "../../../../assets/arrow-right-white.svg";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../../Context/ContextGlobal";

export default function Invitation({ email }) {
  const history = useHistory();
  const { globalActions } = useGlobal();

  const handleContinue = async () => {
    history.push("/main");
  };

  const handleFullScreen = () => {
    history.push("/main");
    globalActions.setFullScreen(true);
  };

  return (
    <div className={`${styles.form_container} ${styles.invitation}`}>
      <h1>
        The Paco Rabanne team invites you to discover the Fall/Winter 22
        Collection
      </h1>

      <p className={styles.handwriting}>Dear partners and friends,</p>
      <p className={styles.handwriting}>
        Thanks to the new model led by the capsules & 2 collections and the
        loyalty of the whole Paco community, our business has shown a strong
        come back in 2021. Thank you for your continuous support !
      </p>
      <p className={styles.handwriting}>
        This new Fall Winter collection continues to pay tribute to our specific
        know-how, mixed with a more dailywear proposition to meet the Paco
        consumer needs.
      </p>
      <p className={styles.handwriting}>I hope you will enjoy it.</p>
      <p className={styles.handwriting}>Bastien Daguzan</p>

      <div className={styles.button_container}>
        {/* <button className={styles.request_btn} onClick={handleFullScreen}>
          Continue in full screen <ArrowRightWhite />
        </button> */}
        <button className={styles.transparent_btn} onClick={handleContinue}>
          CONTINUE <ArrowRightWhite />
        </button>
      </div>
    </div>
  );
}
