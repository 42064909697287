import React, { useEffect, useRef, useState } from "react";
import ModalBestSellers from "../../Components/Modal/ModalBestSellers/ModalBestSellers";
import ModalLook from "../../Components/Modal/ModalLook/ModalLook";
import { useLooks } from "../../Context/ContextLooks";
import { getItemStorage } from "../../utils/localStorage";
import styles from "./Main.module.scss";
import MainEndButtons from "./MainEndButtons";
import MainLookBox from "./MainLookBox";
import MainLookFrames from "./MainLookFrames";
import MainVideo from "./MainVideo";
import MainVideoWaitEnd from "./MainVideoWaitEnd";
import MainVolumeControl from "./MainVolumeControl";
import MainNavBar from "./MainNavBar";
import DiaryContainer from "../Diary/DiaryContainer";
import ScrollTrigger from "../../Components/Shared/ScrollTrigger/ScrollTrigger";
import OverlayMenu from "../../Components/OverlayMenu/OverlayMenu";
import { useGlobal } from "../../Context/ContextGlobal";
import { useBuyer } from "../../Context/ContextBuyer";
import OrderSideBar from "../Order/OrderSideBar/OrderSideBar";
import AdminOrderSideBar from "../Order/AdminOrderSideBar/AdminOrderSideBar";
import DisguiseWrap from "../Auth/DisguiseWrap";

export default function Main() {
  const videoRef = useRef(null);
  const videoEndRef = useRef();
  const { looks } = useLooks();
  const [allLooks, setAllLooks] = useState([]);
  useEffect(() => {
    setAllLooks(looks);
  }, [looks]);
  const { showOnlyVideo, globalActions, showFullScreen, openMenu } =
    useGlobal();
  const { isNewLogIn, buyerActions } = useBuyer();

  const [isPlaying, setIsPlaying] = useState(true);
  const [showReplay, setShowReplay] = useState(false);
  const [showLook, setShowLook] = useState(false);
  const [currentLook, setCurrentLook] = useState(null);

  useEffect(() => {
    openMenu && videoRef.current.pause();
    !openMenu && videoRef.current.play();
  }, [openMenu]);

  useEffect(() => {
    console.log(showFullScreen);
    showFullScreen && globalActions.enterFullScreenMode();
  }, [showFullScreen]);

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      event.code === "Escape" && globalActions.setFullScreen(false);
    });
  }, [globalActions, showFullScreen]);

  const handleClickPreviousLook = () => {
    let lookIndex = looks.findIndex((el) => el.id === currentLook?.id);
    if (lookIndex !== -1 && lookIndex > 1) {
      videoRef.current.currentTime = looks[lookIndex - 1].appearance;
      setCurrentLook(looks[lookIndex - 1]);
    }
  };

  const handleClickNextLook = () => {
    let lookIndex = looks.findIndex((el) => el.id === currentLook?.id);
    if (lookIndex !== -1 && lookIndex < looks.length - 1) {
      videoRef.current.currentTime = looks[lookIndex + 1].appearance;
      setCurrentLook(looks[lookIndex + 1]);
    }
  };

  const skipTo = (look) => {
    videoRef.current.currentTime = look.appearance;
    setCurrentLook(look);

    if (showReplay) setShowReplay(false);
  };

  const toggleShowLook = (shouldOpen) => {
    handleShouldPlay(!shouldOpen);
    setShowLook(shouldOpen);
  };

  const toggleShowBestSellers = (shouldOpen) => {
    handleShouldPlay(!shouldOpen);
    globalActions.showBestsellers(shouldOpen);
  };

  function fadeOut() {
    if (videoRef.current?.volume > 0) {
      videoRef.current.volume = Math.max(
        Math.round((videoRef.current.volume - 0.2) * 10) / 10,
        0
      );
      setTimeout(() => fadeOut(), 5);
    } else if (videoRef.current) {
      videoRef.current.pause();
    }
  }

  function fadeIn() {
    if (videoRef.current?.volume < 1) {
      videoRef.current.volume = Math.min(
        Math.round((videoRef.current.volume + 0.2) * 10) / 10,
        1
      );
      setTimeout(() => fadeIn(), 5);
    } else if (videoRef.current) {
      videoRef.current.play();
    }
  }

  const handleShouldPlay = (shouldPlay, toggle) => {
    let newState;
    if (toggle) {
      if (isPlaying) fadeOut();
      if (!isPlaying) fadeIn();
      newState = !isPlaying;
    } else {
      if (shouldPlay) fadeIn();
      if (!shouldPlay) fadeOut();
      newState = shouldPlay;
    }

    setIsPlaying(newState);
  };

  const replay = () => {
    setShowReplay(false);
    setIsPlaying(true);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  useEffect(() => {
    if (
      videoRef.current &&
      getItemStorage("mainVideoTime") &&
      !isNaN(getItemStorage("mainVideoTime"))
    ) {
      console.log("Setting current time...", getItemStorage("mainVideoTime"));
      videoRef.current.currentTime = parseInt(getItemStorage("mainVideoTime"));
    }
  }, [videoRef]);

  useEffect(() => {
    if (showReplay && showOnlyVideo) {
      globalActions.showOnlyVideo(false);
    }
  }, [showReplay, showOnlyVideo, globalActions]);

  useEffect(() => {
    isNewLogIn && replay() && buyerActions.changeNewLogin();
  }, [isNewLogIn]);

  return (
    <div id="scroll-top">
      <DisguiseWrap>
        <OverlayMenu />
        <OrderSideBar />
        <AdminOrderSideBar />
        <ModalBestSellers close={() => toggleShowBestSellers(false)} />
        <div className={styles.page_container} id="video-wrap-container">
          {/* <LoggedInToolbar /> */}
          {showOnlyVideo ? null : (
            <MainNavBar
              showBestSellers={() => toggleShowBestSellers(true)}
              openMenu={() => globalActions.openMenu(true)}
            />
          )}
          {/* <MainTopRightBar  /> */}
          <MainVideo
            hide={showReplay}
            videoRef={videoRef}
            isPlaying={isPlaying}
            togglePlayPause={() => handleShouldPlay(null, true)}
            notifyVideoEnd={() => {
              setShowReplay(true);
              setIsPlaying(false);
            }}
          />
          {showReplay && <MainVideoWaitEnd videoEndRef={videoEndRef} />}
          {!showReplay && (
            <MainLookFrames
              looks={looks}
              videoRef={videoRef}
              setCurrentLook={setCurrentLook}
              skipTo={skipTo}
            />
          )}
          <MainVolumeControl
            showOnlyVideo={showOnlyVideo}
            videoRef={videoRef}
          />
          <MainLookBox
            look={currentLook}
            isPaused={!isPlaying}
            showLook={() => toggleShowLook(true)}
          />
          {showReplay && <MainEndButtons replay={replay} />}
          {showLook && (
            <ModalLook
              look={currentLook}
              handleClickPreviousLook={handleClickPreviousLook}
              handleClickNextLook={handleClickNextLook}
              close={() => toggleShowLook(false)}
            />
          )}
        </div>
        {showOnlyVideo ? null : (
          <ScrollTrigger handlePlayPause={handleShouldPlay} />
        )}
        {showOnlyVideo ? null : <DiaryContainer />}
      </DisguiseWrap>
    </div>
  );
}
