import React, { useRef, useState } from "react";
import styles from "../../Modal.module.scss";
import UserForm from "../UserForm";
import { useBuyer } from "../../../../Context/ContextBuyer";

export default function CreateAccount({ email, handleChangeScreen }) {
  const formRef = useRef();
  const [disabled, setDisabled] = useState(false);

  const inputFirstNameRef = useRef();
  const inputLastNameRef = useRef();
  const inputCodeRef = useRef();
  const inputStoreNameRef = useRef();
  const inputCountryRef = useRef();
  const inputRetailRef = useRef();
  const inputWholesaleRef = useRef();

  const { buyerForCreation, buyerActions } = useBuyer();

  return (
    <div className={`${styles.form_container} ${styles.screen_create_account}`}>
      <form
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          const submitter = e.nativeEvent.submitter.value;

          if (submitter === "REQUEST ACCESS") {
            buyerActions.requestAccess({
              email: email,
              firstname: inputFirstNameRef.current.value,
              lastname: inputLastNameRef.current.value,
              storeName: inputStoreNameRef.current.value,
              country: inputCountryRef.current.value,
              wholesaleCurrency: inputWholesaleRef.current.value,
              retailCurrency: inputRetailRef.current.value,
              id: buyerForCreation.id,
            });
            setDisabled(true);
          } else if (submitter === "CREATE ACCOUNT") {
            const res = await buyerActions.createAccount({
              email: email,
              firstname: inputFirstNameRef.current.value,
              lastname: inputLastNameRef.current.value,
              storeName: inputStoreNameRef.current.value,
              country: inputCountryRef.current.value,
              wholesaleCurrency: inputWholesaleRef.current.value,
              retailCurrency: inputRetailRef.current.value,
              code: inputCodeRef.current.value,
              id: buyerForCreation.id,
            });

            if (res.ok) {
              handleChangeScreen("status");
            }
          }
        }}
      >
        <h1 className={styles.center}>
          <p>Please enter your information to create an account</p>
        </h1>

        <UserForm
          inputFirstNameRef={inputFirstNameRef}
          inputCodeRef={inputCodeRef}
          inputLastNameRef={inputLastNameRef}
          inputStoreNameRef={inputStoreNameRef}
          inputCountryRef={inputCountryRef}
          inputRetailRef={inputRetailRef}
          inputWholesaleRef={inputWholesaleRef}
          defaultValues={buyerForCreation}
        />
        {/* <div className={styles.names_container}>
          <p className={styles.notes}>*Required</p>
          <p className={styles.notes}>
            By clicking on “Request access” you confirm you have read the
            privacy policy and consent to the processing of your personal data
            by Paco Rabanne.
          </p>
        </div> */}
        <p className={styles.helper}>
          If the Paco Rabanne team hasn’t sent you the code, please click on
          “Request Access”. You will receive an email shortly.
        </p>
        <div className={styles.button_container}>
          <input
            className={styles.submit_btn}
            type="submit"
            value={"CREATE ACCOUNT"}
            submit
          />
          <input
            className={[
              styles.request_btn,
              disabled ? styles.request_btn_disable : null,
            ].join(" ")}
            type="submit"
            value={!disabled ? "REQUEST ACCESS" : "REQUEST SENT"}
            submit
          />
        </div>
      </form>
    </div>
  );
}
