import React, {useState, useEffect} from "react";
import styles from "./Order.module.scss";
import OrderQuantities from "./OrderQuantities";
import OrderTopRightBar from "./OrderTopRightBar";
import OrderExport from "./OrderExport";
import OrderTopLeftBar from "./OrderTopLeftBar";

export default function Order(props) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (step === 0) {
    return (
      <div className={styles.order_wrap}>
        {/* <LoggedInToolbar dark /> */}

        <div className={styles.flex_container}>
          <div className={styles.action_wrap}>
            <OrderTopLeftBar />
            <OrderTopRightBar
              step={step}
              action={() => setStep(step === 1 ? 0 : 1)}
            />
          </div>
          <OrderQuantities />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.thank_you_page_container}>
      <OrderExport />
    </div>
  );
}
