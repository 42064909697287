import React, { useState, useEffect } from "react";
import MagnifyImage from "../../Shared/MagnifyImage";
import styles from "../Card.module.scss";
import CardSKU from "../CardSKU/CardSKU";
import CardSKURemove from "../CardSKU/CardSKURemove";
import CardSKURemoveDecision from "../CardSKU/CardSKURemoveDecision";
import CardSKUAddToOrder from "../CardSKU/CardSKUAddToOrder";
import { useDiary } from "../../../Context/ContextDiary";
import Loader from "../../Shared/Loader";
import { getPriceInCurrency } from "../../../utils/utils";
import { getCurrencySymbol } from "../../../services/utils";

export default function CardLookWithSKU({ showProduct, setShowProduct, look }) {
  const { order, basket, diaryActions, product, productModal } = useDiary();

  const [indCardToShowDeleteModule, setIndCardToShowDeleteModule] =
    useState(null);
  const [displayImageIndex, setDisplayImageIndex] = useState(0);
  const [price, setPrice] = useState(null);

  const handleRemoveProductMiddleman = (e, sku) => {
    setIndCardToShowDeleteModule(null);
    diaryActions.removeSKU(basket.id, sku);
  };

  useEffect(() => {
    if (showProduct) {
      diaryActions.getProductData(showProduct.id);
    }
    setDisplayImageIndex(0);
  }, [showProduct]);

  useEffect(() => {
    if (product) {
      setPrice(
        getPriceInCurrency(
          product.prices,
          productModal.currency.retail,
          productModal.currency.wholesale
        )
      );
    }
  }, [product]);

  return (
    <div className={styles.card_look_liked_container}>
      <div className={styles.image_container}>
        {!product && (
          <div className={styles.loaderContainer}>
            <Loader
              type="MutatingDots"
              color={"black"}
              secondaryColor={"grey"}
            />
          </div>
        )}
        {product && (
          <div>
            {product.images?.[0]?.url && (
              <MagnifyImage
                imageUrl={product.images?.[displayImageIndex]?.url}
                alt={look.name}
              />
            )}

            <div className={styles.footnote_container}>
              <span className={styles.little_sku_name}>
                {showProduct.productSkuId}
              </span>
              <span className={styles.little_sku_name}>{showProduct.sku}</span>
              {price && (
                <span className={styles.little_sku_price}>
                  RETAIL : {price?.price_retail}{" "}
                  {getCurrencySymbol(price?.price_currency_retail)}
                  <br />
                  WHOLESALE : {price?.price_wholesale}{" "}
                  {getCurrencySymbol(price?.price_currency)}
                </span>
              )}
            </div>
            <div className={styles.footnote_container}>
              {product.images?.map((img, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.img_dot}
                   ${displayImageIndex === index ? styles.active : ""}
                   `}
                    onClick={() => setDisplayImageIndex(index)}
                  />
                );
              })}
            </div>
          </div>
        )}

        {/* <div className={styles.add_button_wrap}>
          {canAddToLiked ? (
            <AddIcon
              onClick={() => {
                canAddToLiked && diaryActions.addLook(look, buyer.id);
              }}
            />
          ) : (
            <AddBlackIcon
              onClick={(e) => {
                console.log("handle remove look");
                // diaryActions.handleRemoveLook(e, look);
                setShowRemoveLookDecisionModal(true);
              }}
            />
          )}
        </div> */}

        {/* {showAllSKUs || !handleRemoveLook ? null : (
          <CardSKURemove
            handleRemove={() => {
              setShowRemoveLookDecisionModal(true);
            }}
          />
        )}
        {showRemoveLookDecisionModal ? (
          <CardSKURemoveDecision
            ok={(e) => removeLook(e, look)}
            cancel={() => setShowRemoveLookDecisionModal(null)}
          />
        ) : null} */}
      </div>
      <div className={styles.content_container}>
        <div className={styles.skus_grid}>
          {look?.products?.map((product, index) => {
            const selectedDiary = order?.find((d) => d.id === product.id);

            if (!product.orderedImages?.[0]?.url) return null;
            return (
              <div
                className={`${styles.sku_container} ${
                  showProduct.id === product.id
                }`}
                key={index}
              >
                {product?.orderedImages[0] && (
                  <CardSKU
                    image={product.orderedImages[0]}
                    id={product.id}
                    handleClick={() => {
                      if (showProduct.id === product.id) {
                        setShowProduct(null);
                      } else {
                        setShowProduct(product);
                      }
                    }}
                  />
                )}
                {selectedDiary ? (
                  <CardSKURemove
                    handleRemove={() => {
                      setIndCardToShowDeleteModule(index);
                    }}
                  />
                ) : null}

                <CardSKUAddToOrder
                  product={product}
                  added={selectedDiary}
                  handleRemove={() => setIndCardToShowDeleteModule(index)}
                />

                {typeof indCardToShowDeleteModule === "number" &&
                indCardToShowDeleteModule === index ? (
                  <CardSKURemoveDecision
                    lookOrProduct={"product"}
                    ok={(e) => handleRemoveProductMiddleman(e, product)}
                    cancel={() => setIndCardToShowDeleteModule(null)}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
