export const getItemStorage = (key) => {
  return localStorage.getItem(key);
};

export const setItemStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeItemStorage = (key) => {
  return localStorage.removeItem(key);
};
