import React from "react";
import styles from "../Modal.module.scss";
import {countries} from "../../../utils/utils";

export default function ModalLogin({
  error,
  inputFirstNameRef,
  inputLastNameRef,
  inputStoreNameRef,
  inputCountryRef,
  inputRetailRef,
  inputWholesaleRef,
  inputCodeRef,
  defaultValues,
}) {
  return (
    <>
      <div className={styles.names_container}>
        <input
          required
          ref={inputFirstNameRef}
          placeholder={"First name*"}
          defaultValue={defaultValues?.firstname}
        ></input>
        <input
          required
          ref={inputLastNameRef}
          placeholder={"Last name*"}
          defaultValue={defaultValues?.lastname}
        ></input>
      </div>
      <div className={styles.names_container}>
        <input
          required
          ref={inputStoreNameRef}
          placeholder={"Store name*"}
          defaultValue={defaultValues?.storeName}
        ></input>
        <select
          required
          ref={inputCountryRef}
          defaultValue={defaultValues?.country || ""}
        >
          <option hidden disabled value={""}>
            Country*
          </option>
          {countries.map((country, index) => {
            return (
              <option value={country} key={index} className={styles.dropdown}>
                {country}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.names_container}>
        <select required ref={inputWholesaleRef} placeholder={"Wholesale"}>
          {["Wholesale price EUR (€)", "WUSL ($)", "WEXC (元 / ¥)"].map(
            (curr, index) => {
              return (
                <option value={curr} key={index} className={styles.dropdown}>
                  {curr}
                </option>
              );
            }
          )}
        </select>
        <select required ref={inputRetailRef} placeholder={"Retail"}>
          {[
            "Retail price EUR (€)",
            "RUSL ($)",
            "RCNY (元 / ¥)",
            "RJPY (¥)",
            "RAED (د. إ;)",
            "RAUD (AU$)",
            "RGBP (£)",
            "RHKD (HK$)",
            "RKRW (₩)",
          ].map((curr, index) => {
            return (
              <option value={curr} key={index} className={styles.dropdown}>
                {curr}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.names_container}>
        {inputCodeRef ? (
          <input
            type="password"
            className={styles.fw_input}
            ref={inputCodeRef}
            placeholder={"Insert code"}
          ></input>
        ) : null}
      </div>

      {error && <div className={styles.error_container}>{error}</div>}
    </>
  );
}
