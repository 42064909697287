import React, {useRef} from "react";
import styles from "../../Modal.module.scss";
import UserForm from "../UserForm";
import {useBuyer} from "../../../../Context/ContextBuyer";
import {useHistory} from "react-router-dom";

export default function Access() {
  const {buyer, buyerActions} = useBuyer();

  const formRef = useRef();

  const inputFirstNameRef = useRef();
  const inputLastNameRef = useRef();
  const inputStoreNameRef = useRef();
  const inputCountryRef = useRef();
  const inputRetailRef = useRef();
  const inputWholesaleRef = useRef();

  const history = useHistory();

  return (
    <div className={`${styles.form_container} ${styles.screen_access}`}>
      <form
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          const res = await buyerActions.updateAccount({
            email: buyer.mail,
            firstname: inputFirstNameRef.current.value,
            lastname: inputLastNameRef.current.value,
            storeName: inputStoreNameRef.current.value,
            country: inputCountryRef.current.value,
            wholesaleCurrency: inputWholesaleRef.current.value,
            retailCurrency: inputRetailRef.current.value,
            id: buyer.id,
          });

          if (res) history.push("/invitation");
        }}
      >
        <h1 className={styles.center}>
          <p>Please enter your information to place an order</p>
        </h1>

        <UserForm
          inputFirstNameRef={inputFirstNameRef}
          inputLastNameRef={inputLastNameRef}
          inputStoreNameRef={inputStoreNameRef}
          inputCountryRef={inputCountryRef}
          inputRetailRef={inputRetailRef}
          inputWholesaleRef={inputWholesaleRef}
          defaultValues={buyer}
        />
        <div className={styles.names_container}>
          <p className={styles.notes}>*Required</p>
          <p className={styles.notes}>
          Please note this information will only be requested when you first log in. For future access we will only request your e-mail. By clicking on “Start experience” you confirm that you consent to the processing of your personal data by Paco Rabanne.
          </p>
        </div>

        <input
          className={styles.submit_btn}
          type="submit"
          value={"START EXPERIENCE"}
          submit
        />
      </form>
    </div>
  );
}
