import React from "react";
import Button from "../../Components/Button/Button";
import { useDiary } from "../../Context/ContextDiary";
import styles from "./Main.module.scss";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { ReactComponent as LikeIcon } from "../../assets/like.svg";
import { ReactComponent as LikeDarkIcon } from "../../assets/like_dark.svg";
import { useBuyer } from "../../Context/ContextBuyer";

export default function MainLookBox({ look, showLook }) {
  const { looksLikedIds, diaryActions } = useDiary();
  const { buyer } = useBuyer();


  const isLiked = (id) => {
    return looksLikedIds.some((e) => e.id === id);
  };

  const toggleLike = (e) => {
    e.stopPropagation();
    if (isLiked(look.id)) diaryActions.removeLook(look, buyer.id);
    else diaryActions.addLook(look, buyer.id);
  };

  return (
    <div
      // ${isPaused ? styles.show_image : ""}
      className={`${styles.look_box}`}
    >
      {/* {isPaused ? (
        <MainLookBoxLike isLiked={isLiked(look.id)} toggleLike={toggleLike} />
      ) : (
        <Button variant={"outlined"} size={"big"} text={`LOOK ${look.order}`} />
      )} */}

      {look && (
        <>
          <Button
            rounded
            variant={"outlined"}
            size={"big"}
            text={<div>LOOK {look.order}</div>}
            action={showLook}
          />
          <div className={styles.icon_enlarge_container} onClick={showLook}>
            <EyeIcon />
          </div>
          <LookLike isLiked={isLiked(look.id)} toggleLike={toggleLike} />
        </>
      )}
    </div>
  );
}

function LookLike({ isLiked, toggleLike }) {
  return (
    <div className={styles.look_box_icon_like_container} onClick={toggleLike}>
      {isLiked && <LikeDarkIcon />}
      {!isLiked && <LikeIcon />}
    </div>
  );
}

// function MainLookBoxLike({ isLiked, toggleLike }) {
//   return (
//     <div className={styles.look_box_icon_like_container} onClick={toggleLike}>
//       {isLiked && <img src={iconLiked} alt={"liked"} />}
//       {!isLiked && <img src={iconUnliked} alt={"unliked"} />}
//     </div>
//   );
// }
