import React from "react";
import styles from "./Main.module.scss";

export default function MainVideo({
  videoRef,
  isPlaying,
  togglePlayPause,
  notifyVideoEnd,
  hide,
}) {
  const videoLoadStart = () => {
    // videoRef.current.play();
    // show loading animation ..
  };

  const videoLoaded = () => {
    // remove loading animation ..
    videoRef.current.play();
  };

  return (
    <div
      className={`${styles.video_wrapper} ${
        styles[isPlaying ? "pause" : "play"]
      }`}
      style={
        hide
          ? {
              zIndex: -1,
            }
          : {}
      }
    >
      <video
        ref={videoRef}
        className="player"
        src="https://storage.googleapis.com/paco-assets/PR_FW22_Horizontal_VALIDE_Small_Size_5mbits%20(1).mp4"
        autoPlay
        playsInline
        muted
        onLoadStart={videoLoadStart}
        onLoadedData={videoLoaded}
        onEnded={notifyVideoEnd}
        onClick={togglePlayPause}
      ></video>
    </div>
  );
}
