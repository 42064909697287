import React, {useReducer, useContext, useMemo} from "react";

const Global = React.createContext();

const initialState = {
  openMenu: false,
  openOrderValidation: false,
  openAdminOrders: false,
  showBestsellers: false,
  showOnlyVideo: true,
  showFullScreen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_OPEN_VALIDATION":
      return Object.assign({}, state, {
        ...state,
        openOrderValidation: action.openOrderValidation,
      });
    case "SET_OPEN_ADMIN":
      return Object.assign({}, state, {
        ...state,
        openAdminOrders: action.openAdminOrders,
        openMenu: false,
      });
    case "SET_OPEN_MENU":
      return Object.assign({}, state, {
        ...state,
        openMenu: action.openMenu,
        showBestsellers: !action.openMenu ? state.showBestsellers : false,
      });
    case "SET_SHOW_BESTSELLERS":
      return Object.assign({}, state, {
        ...state,
        showBestsellers: action.showBestsellers,
        openMenu: !action.showBestsellers ? state.openMenu : false,
      });
    case "SET_SHOW_ONLY_VIDEO":
      return Object.assign({}, state, {
        ...state,
        showOnlyVideo: action.showOnlyVideo,
      });
    case "SET_FULLSCREEN":
      return Object.assign({}, state, {
        ...state,
        showFullScreen: action.showFullScreen,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const GlobalProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      openMenu: (openMenu) => {
        dispatch({type: "SET_OPEN_MENU", openMenu});
      },
      openOrderValidation: (openOrderValidation) => {
        dispatch({type: "SET_OPEN_VALIDATION", openOrderValidation});
      },
      openAdminOrders: (openAdminOrders) => {
        dispatch({type: "SET_OPEN_ADMIN", openAdminOrders});
      },
      showBestsellers: (showBestsellers) => {
        dispatch({type: "SET_SHOW_BESTSELLERS", showBestsellers});
      },
      showOnlyVideo: (bol) => {
        dispatch({type: "SET_SHOW_ONLY_VIDEO", showOnlyVideo: bol});
      },
      setFullScreen: (bol) => {
        dispatch({type: "SET_FULLSCREEN", showFullScreen: bol});
      },

      enterFullScreenMode: () => {
        const videoWrap = document.getElementById("video-wrap-container");

        if (videoWrap.requestFullscreen) videoWrap.requestFullscreen();
        else if (videoWrap.webkitRequestFullscreen)
          videoWrap.webkitRequestFullscreen();
        else if (videoWrap.msRequestFullScreen) videoWrap.msRequestFullScreen();
      },
    }),
    []
  );

  return (
    <Global.Provider
      value={{
        showFullScreen: state.showFullScreen,
        openMenu: state.openMenu,
        openOrderValidation: state.openOrderValidation,
        openAdminOrders: state.openAdminOrders,
        showBestsellers: state.showBestsellers,
        showOnlyVideo: state.showOnlyVideo,
        globalActions: actions,
      }}
    >
      {children}
    </Global.Provider>
  );
};

export const useGlobal = () => useContext(Global);
