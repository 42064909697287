import React, { useEffect, useState } from "react";
import styles from "./Inspirations.module.scss";
import { useInspirations } from "../../Context/ContextInspirations";
import { useGlobal } from "../../Context/ContextGlobal";
import { useDiary } from "../../Context/ContextDiary";
import InspirationsCurators from "./InspirationsCurators";
import InspirationsHeader from "./InspirationsHeader";
import InspirationsDiary from "./InspirationsDiary";
import Loader from "../../Components/Shared/Loader";
import Logo from "../../Components/Logo/Logo";
import { ReactComponent as ShopBag } from "../../assets/Union.svg";
import { ReactComponent as IconLeft } from "../../assets/icon-left.svg";
import { Link } from "react-router-dom";
import OverlayMenu from "../../Components/OverlayMenu/OverlayMenu";
import ModalBestSellers from "../../Components/Modal/ModalBestSellers/ModalBestSellers";
import { gsap } from "gsap";
import config from "../../config/config";
import { useHistory } from "react-router-dom";

export default function Inspirations(props) {
  const { inspirations, inspirationsActions } = useInspirations();
  const { globalActions } = useGlobal();
  const { order } = useDiary();

  const [clickedInspiration, setClickedInspiration] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (!inspirations?.length) {
      inspirationsActions.fetchInspirations();
    } else {
      setClickedInspiration(inspirations[0]);
    }
  }, [inspirations, inspirationsActions, setClickedInspiration]);

  const handleScrollDown = () => {
    window.location.href = "/main?to=order";
  };

  const openMenu = () => {
    globalActions.openMenu(true);
  };

  return (
    <div className={styles.page_container}>
      <OverlayMenu />
      <ModalBestSellers />
      <div className={styles.navmenu_row}>
        <div className={styles.flex}>
          <Logo dark />
          <Link to="/main">
            <IconLeft className={styles.icon_left} />
          </Link>
        </div>
        <h1>Curated by</h1>
        <div className={styles.navmenu_actions}>
          <div
            className={`${styles.shop_bag_wrap} ${styles.flex}`}
            onClick={handleScrollDown}
          >
            <ShopBag style={{ filter: "invert(100%)" }} />
            <span>{order?.length || 0}</span>
          </div>
          <div className={styles.menu_burger} onClick={openMenu}></div>
        </div>
      </div>
      <div className={styles.flex_container}>
        <InspirationsCurators
          curators={inspirations}
          select={setClickedInspiration}
          selectedId={clickedInspiration?.id}
        />
        <InspirationsHeader inspiration={clickedInspiration} />
        <InspirationsDiary diarySkus={clickedInspiration?.favoritesLooks} />
      </div>
      {!inspirations?.length && (
        <div id="loader">
          <Loader
            type="MutatingDots"
            color={"black"}
            secondaryColor={"grey"}
            height={150}
            width={150}
          />
        </div>
      )}
    </div>
  );
}
