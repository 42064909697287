import React from "react";
import Button from "../../Components/Button/Button";
import styles from "./Order.module.scss";
import iconArrowRight from "../../assets/iconArrowRight.png";

export default function OrderTopRightBar({ step, action }) {
  return (
    <div className={styles.top_right_bar_container}>
      <Button
        rounded
        color={"black"}
        variant={"outlined"}
        text={
          step === 0 ? (
            <div>
              Checkout
              <img
                src={iconArrowRight}
                alt={"back icon"}
                style={{
                  width: "1rem",
                  height: "1rem",
                  filter: "invert(100%)",
                }}
              />
            </div>
          ) : (
            <div>
              <img
                src={iconArrowRight}
                alt={"back icon"}
                style={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: "1rem",
                  filter: "invert(100%)",
                  transform: "rotate(180deg)",
                }}
              />
              Back
            </div>
          )
        }
        action={action}
      />
    </div>
  );
}
