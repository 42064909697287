import styles from "./AdminOrderSideBar.module.scss";
import iconClose from "../../../assets//iconClose.png";
import { spaceEachThreeNumbers } from "../../../utils/utils";
import { useRef, useEffect } from "react";
import gsap from "gsap";
import { useGlobal } from "../../../Context/ContextGlobal";
import { useDiary } from "../../../Context/ContextDiary";
import { useState } from "react";
import api from "../../../services/api";
import { useBuyer } from "../../../Context/ContextBuyer";
import Loader from "../../../Components/Shared/Loader";
import add from "../../../assets/add.svg";
import { useHistory } from "react-router-dom";

const AdminOrderSideBar = () => {
  const [allClients, setAllClients] = useState(null);
  const [allClientsDisplayed, setAllClientsDisplayed] = useState(null);
  const { openAdminOrders, globalActions } = useGlobal();

  const { order } = useDiary();
  const bayer = useBuyer();

  const mask = useRef(null);
  const container = useRef(null);
  const cross = useRef(null);

  const close = () => {
    if (openAdminOrders) {
      setAllClients(null);
      setAllClientsDisplayed(null);
      globalActions.openAdminOrders(false);
      gsap.to(container.current, {
        x: "100vw",
        duration: 0.7,
      });
      gsap.to(mask.current, { duration: 0.7, opacity: 0 });
      gsap.to(mask.current, { display: "none" });
      gsap.to(cross.current, { allClients, x: "100vw", duration: 0.7 });
    }
  };
  const handlefilterorder = (filter) => {
    setAllClientsDisplayed(
      allClients.filter((order) => {
        if (
          order &&
          (order.firstname?.toLowerCase().includes(filter?.toLowerCase()) ||
            order.lastname?.toLowerCase().includes(filter?.toLowerCase()) ||
            order.compteClient?.toLowerCase().includes(filter?.toLowerCase()) ||
            order.customerCode?.toLowerCase().includes(filter?.toLowerCase()))
        ) {
          return true;
        }
        return false;
      })
    );
  };

  useEffect(() => {
    const getAllRetailers = async () => {
      const res = await api.retailers.getAllRetailers();
      setAllClients(res);
      setAllClientsDisplayed(res);
    };

    if (openAdminOrders) {
      globalActions.openMenu(false);
      // order.map((item) => {
      //   if (item.quantity) {
      //     setSkuNumber((prevSkuNumber) => {
      //       return prevSkuNumber + 1;
      //     });
      //     for (const [value] of Object.entries(item.quantity)) {
      //       setSkuQuantity((prevQuantity) => {
      //         return prevQuantity + value.quantity;
      //       });
      //     }
      //   }
      //   return null;
      // });

      gsap.to(cross.current, { display: "block" });
      gsap.to(mask.current, { display: "block" });
      gsap.to(mask.current, { duration: 0.7, opacity: 1 });
      gsap.to(container.current, {
        x: "0",
        duration: 0.7,
      });
      gsap.to(cross.current, {
        x: "0",
        duration: 0.7,
      });
      getAllRetailers();
    }
  }, [order, openAdminOrders, bayer.buyer.mail]);

  return (
    <>
      <div className={styles.orderSideBarMask} ref={mask}></div>
      <div className={styles.orderSideBarContainer} ref={container}>
        <div className={styles.OSBContent}>
          {!allClients && (
            <div className={styles.loaderContainer}>
              <Loader
                type="MutatingDots"
                color={"black"}
                secondaryColor={"grey"}
                height={150}
                width={150}
              />
            </div>
          )}
          {allClients && (
            <div className={styles.OSBTitles}>
              <h1 className={styles.OSBTitle}>ADMIN</h1>
              <p className={styles.OSBSubTitle}>
                Search by client code or store name
              </p>
              <input
                className={styles.inputOrderFilter}
                type="text"
                placeholder={"filter"}
                onChange={(e) => handlefilterorder(e.target.value)}
              />
            </div>
          )}
          {allClientsDisplayed &&
            allClientsDisplayed.map((d, index) => {
              return (
                <OrderSideBarUserRow
                  key={index}
                  firstname={d.firstname}
                  lastname={d.lastname}
                  storeName={d.storeName}
                  wholesaleTotal={d.basket?.[0].wholesaleTotal || 0}
                  clientCode={d.customerCode}
                  currency={"$"}
                  skuQt={d.basket?.[0].productNumber || 0} //FIXME: find value
                  discount={d.discountAmount}
                  email={d.mail || null}
                  close={close}
                />
              );
            })}
        </div>
      </div>
      <img
        ref={cross}
        src={iconClose}
        alt="Close"
        onClick={() => close()}
        className={styles.orderSideBarClose}
      />
    </>
  );
};

export default AdminOrderSideBar;

const OrderSideBarUserRow = ({
  firstname,
  lastname,
  storeName,
  wholesaleTotal,
  clientCode,
  currency,
  discount,
  skuQt,
  email,
  close,
}) => {
  const { buyerActions } = useBuyer();
  const history = useHistory();
  useEffect(() => {
    buyerActions.getReteilersBySellerId();

    // TODO JULIETTE or ANTOINE: this action will fetch all my clients.
    // You will need to use the data to display my clients here.
    // YOu will have to be a seller (in airtable) to be able to have clients
  }, []);
  return (
    <div className={styles.OrderSideBarUserRowContainer}>
      <hr className={styles.OSBPRHr} />
      <div className={styles.OSBPRContentCol}>
        <img
          src={add}
          alt={`add`}
          className={styles.addIcon}
          onClick={async () => {
            console.log(email);
            if (email) {
              const res = await buyerActions.applyDisguise(email);

              if (res.ok) {
                history.push("/main");
                close();
              }
            }
          }}
        />
        <div className={styles.OSBPRProperties}>
          <div className={styles.OSBPRPropertiesTitles}>
            <p className={styles.OSBPRPropertesClientName}>
              {firstname} {lastname}
            </p>
            <p className={styles.OSBPRPropertesTitle}>Client</p>
            <p className={styles.OSBPRPropertesTitle}>Code client</p>
            <p className={styles.OSBPRPropertesTitle}>Sku in selection</p>
            <p className={styles.OSBPRPropertesTitleTotal}>total wholesale</p>
            {discount && (
              <p className={styles.OSBPRPropertesTitle}>discount applied</p>
            )}
          </div>
          <div className={styles.OSBPRPropertiesValuesAdmin}>
            <p className={styles.OSBPRPropertesValue}>{storeName}</p>
            <p className={styles.OSBPRPropertesValue}>{clientCode || "XX"}</p>
            <p className={styles.OSBPRPropertesValue}>
              {spaceEachThreeNumbers(skuQt)}
            </p>
            <p className={styles.OSBPRPropertesValueTotal}>
              {spaceEachThreeNumbers(wholesaleTotal)} {currency}
            </p>
            {discount && (
              <p className={styles.OSBPRPropertesValue}>
                {spaceEachThreeNumbers(discount)}%
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
