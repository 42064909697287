import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { BuyerProvider, useBuyer } from "./Context/ContextBuyer";
import { DiaryProvider } from "./Context/ContextDiary";
import { LooksProvider } from "./Context/ContextLooks";
import { GlobalProvider } from "./Context/ContextGlobal";
import Inspirations from "./Layouts/Inspirations/Inspirations";
import Main from "./Layouts/Main/Main";
import Welcome from "./Layouts/Welcome/Welcome";
import Auth from "./Layouts/Auth/Auth";
import Loader from "./Components/Shared/Loader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { InspirationsProvider } from "./Context/ContextInspirations";
import Order from "./Layouts/Order/Order";
import ExportPDF from "./Layouts/ExportPDF/ExportPDF";
import { hotjar } from "react-hotjar";

hotjar.initialize(2471829, 6);

function App() {
  return (
    <DiaryProvider>
      <BuyerProvider>
        <LooksProvider>
          <InspirationsProvider>
            <GlobalProvider>
              <AppRouter />
            </GlobalProvider>
          </InspirationsProvider>
        </LooksProvider>
      </BuyerProvider>
    </DiaryProvider>
  );
}

function AppRouter() {
  const { buyer, isLoading } = useBuyer();
  if (isLoading)
    return (
      <div id="loader">
        <Loader
          type="MutatingDots"
          color={"black"}
          secondaryColor={"grey"}
          height={150}
          width={150}
        />
      </div>
    );
  return (
    <Router>
      <Switch>
        <Route path="/main">{buyer ? <Main /> : <Redirect to="/" />}</Route>
        {/* <Route path="/diary">{buyer ? <Diary /> : <Redirect to="/" />}</Route> */}
        <Route path="/order">{buyer ? <Order /> : <Redirect to="/" />}</Route>
        <Route path="/pdf">{buyer ? <ExportPDF /> : <Redirect to="/" />}</Route>
        <Route path="/inspirations">
          {buyer ? <Inspirations /> : <Redirect to="/" />}
        </Route>
        <Route path="/start-experience">{<Welcome />}</Route>
        <Route path="/invitation">{<Welcome />}</Route>
        <Route path="/disguise">{<Welcome />}</Route>
        <Route path="/auth">{<Auth />}</Route>

        <Route path="/">{buyer ? <Redirect to="/main" /> : <Welcome />}</Route>
      </Switch>
    </Router>
  );
}

export default App;
