export const extractSymbol = (val) => {
  try {
    return val.substring(val.indexOf("(") + 1)[0];
  } catch (err) {
    return "";
  }
};

export const getPriceInCurrency = (prices, priceRetail, priceWholesale) => {
  let currency;

  if (priceRetail.includes("EUR")) currency = "WEUR-REUR";
  if (priceRetail.includes("JPY")) currency = "WEUR-RJPY";
  if (priceRetail.includes("GBP")) currency = "WEUR-RGBP";
  if (priceRetail.includes("BAS")) currency = "WBAS-REUR";
  if (priceRetail.includes("USL")) {
    if (priceWholesale.includes("USL")) {
      currency = "WUSL-RUSL";
    } else {
      currency = "WEUR-RUSL";
    }
  }
  if (priceRetail.includes("AED")) currency = "WEUR-RAED";
  if (priceRetail.includes("CNY")) currency = "WEUR-RCNY";
  if (priceRetail.includes("HKD")) currency = "WEUR-RHKD";
  if (priceRetail.includes("AUD")) currency = "WEUR-RAUD";
  if (priceRetail.includes("KRW")) currency = "WEUR-RKRW";
  if (priceRetail.includes("CNY")) currency = "WEXC-RCNY";

  let pricesByCurrency = prices?.find((p) => p.price_label === currency);

  //   return `${Math.round(getValue() * qty * 10) / 10}${extractSymbol(rawCurr)}`;

  return pricesByCurrency;
};

export const getCurrencySymbol = (priceType) => {
  if (priceType.includes("EUR")) return "€";
  if (priceType.includes("JPY")) return "¥";
  if (priceType.includes("GBP")) return "£";
  if (priceType.includes("BAS")) return "";
  if (priceType.includes("USL")) return "$";
  if (priceType.includes("AED")) return "د. إ;";
  if (priceType.includes("HKD")) return "HK$";
  if (priceType.includes("AUD")) return "AU$";
  if (priceType.includes("KRW")) return "₩";
  if (priceType.includes("CNY")) return "元 / ¥";
};

export const formatCurrency = (value, type) => {
  let formatted;

  if (type === "wholesale") {
    formatted = "W";
  } else {
    formatted = "R";
  }

  return `${formatted}${value}`.toUpperCase();
};

export const csvToJson = (csv) => {
  // isn't it what JSON.parce() doing?
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return result; //JSON
};

export const jsonToCsv = (json) => {
  var fields = Object.keys(json[0]);
  // var replacer = function (key, value) {
  //   return value === null ? "" : value;
  // };
  var csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return row[fieldName] || "";
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\n");
  return csv;
};

export const sortedImgs = (sku, size) => {
  const sizeOrDefault = (img) => {
    return img.thumbnails?.[size]?.url || img.url;
  };

  return sku.imagesId
    ?.sort((a, b) => a.ordreParSku - b.orderParSku)
    .map((img) => sizeOrDefault(img.attachment?.[0]));
};

export const spaceEachThreeNumbers = (number) => {
  let result = "";
  if (typeof number === "number") {
    result = number.toString();
  } else {
    result = number;
  }
  if (result.length > 3) {
    const resultArray = result.split("");
    let finalArray = [];
    resultArray.reverse();
    for (let i = 0; i < resultArray.length; i++) {
      if (i % 3 === 0) {
        finalArray.push(" ");
      }
      finalArray.push(resultArray[i]);
    }
    finalArray.reverse();
    return finalArray.join("");
  }
  return result;
};
