import React, { useEffect } from "react";
import Button from "../../Components/Button/Button";
import styles from "./Main.module.scss";
import Logo from "../../Components/Logo/Logo";
import { ReactComponent as ShopBag } from "../../assets/Union.svg";
import { useDiary } from "../../Context/ContextDiary";
import { useGlobal } from "../../Context/ContextGlobal";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import config from "../../../src/config/config";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollToPlugin);

export default function MainNavBar({ openMenu, showBestSellers }) {
  const { order } = useDiary();
  const { globalActions } = useGlobal();

  const handleScrollDown = () => {
    console.log(window.location.pathname);
    gsap.to(window, {
      duration: config.scrollTiming / 1000,
      scrollTo: "#scroll-next",
    });
  };

  useEffect(() => {
    if (window.location.href.includes("to=order")) {
      handleScrollDown();
    }
  }, []);

  return (
    <div className={styles.main_navbar}>
      <div className={styles.navbar_logo_wrap}>
        <Logo />
      </div>
      <div className={styles.navbar_middle}>
        <Link to={"/inspirations"}>Curated by</Link>
        <Button
          size={"medium"}
          noPadding
          variant={"textOnly"}
          color={"white"}
          uppercase
          text={<div>Best sellers</div>}
          action={showBestSellers}
        />
      </div>
      <div className={styles.navbar_menu}>
        <div className={styles.shop_bag_wrap} onClick={handleScrollDown}>
          <ShopBag />
          <span>{order?.length || 0}</span>
        </div>

        <div className={styles.menu_burger} onClick={openMenu}></div>
      </div>
    </div>
  );
}
