import React from "react";
import styles from "./Main.module.scss";
import Button from "../../Components/Button/Button";
import iconReplay from "../../assets/iconReplay.png";


export default function MainEndButtons({ replay }) {
  return (
    <div className={styles.replay_container}>     
      <Button
        size={"big"}
        variant={"textOnly"}
        text={
          <div>
            Replay{" "}
            <img
              src={iconReplay}
              alt={"iconReplay"}
              style={{ width: 23, height: 23 }}
            />
          </div>
        }
        action={replay}
      />
    </div>
  );
}
