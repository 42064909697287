import React, { useEffect } from "react";
import Button from "../../Components/Button/Button";
import { useDiary } from "../../Context/ContextDiary";
import styles from "./Order.module.scss";
import { useBuyer } from "../../Context/ContextBuyer";
import config from "../../config/config";
import { getItemStorage, setItemStorage } from "../../utils/localStorage";
import { jsonToCsv } from "../../utils/utils";
import { Link } from "react-router-dom";
import iconBack from "../../assets/iconBack.png";
import api from "../../services/api";

export default function OrderExport(props) {
  const { order } = useDiary();
  const { buyer } = useBuyer();

  // function selectElementContents(el) {
  //   var body = document.body,
  //     range,
  //     sel;
  //   if (document.createRange && window.getSelection) {
  //     range = document.createRange();
  //     sel = window.getSelection();
  //     sel.removeAllRanges();
  //     try {
  //       range.selectNodeContents(el);
  //       sel.addRange(range);
  //     } catch (e) {
  //       range.selectNode(el);
  //       sel.addRange(range);
  //     }

  //     document.execCommand("copy");
  //     setShowSuccessCopied(true);
  //     setTimeout(() => {
  //       setShowSuccessCopied(false);
  //     }, 2000);
  //   } else if (body.createTextRange) {
  //     range = body.createTextRange();
  //     range.moveToElementText(el);
  //     range.select();
  //     range.execCommand("Copy");
  //     setShowSuccessCopied(true);
  //     setTimeout(() => {
  //       setShowSuccessCopied(false);
  //     }, 2000);
  //   }
  // }

  const sendEmail = async () => {
    const res = await fetch(config.emailServiceUrl, {
      method: "POST",
      body: JSON.stringify({
        email: buyer.mail,
        name: `${buyer.firstname} ${buyer.lastname}`,
        link: `http://discover.pacorabanne.com/pdf`,
      }),
      headers: {
        "Content-type": "application/json",
      },
    });
    if (res && res.ok) setItemStorage("emailSent", "true");
  };

  const createAirtableOrder = (orderData) => {
    api.orders.getOrder({
      buyers: [getItemStorage("buyerId")],
      firstname: getItemStorage("firstname"),
      lastname: getItemStorage("lastname"),
      storeName: getItemStorage("storeName"),
      country: getItemStorage("country"),
      time: new Date().toISOString(),
      order: orderData.toString(),
    });
  };

  useEffect(() => {
    sendEmail();
    if (order.length) {
      createAirtableOrder(
        jsonToCsv(
          order.map((el) => {
            delete el.orderedImages;
            return el;
          })
        )
      );
    }
  }, []);

  return (
    <>
      <div className={styles.export_container}>
        <h1>Thank you for your time!</h1>
        <p>
          You will receive an email shortly with your selection. <br />
          <br />
          Please send all orders to the sales team by July 7th or enter them
          directly through JOOR.
        </p>
        <div className={styles.btn_container}>
          <a
            href={"https://www.jooraccess.com/pacorabanne"}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button rounded color="black" text={"jooraccess.com/pacorabanne"} />
          </a>
          <Link to={"/main"}>
            <Button
              rounded
              variant={"outlined"}
              color="black"
              text={
                <>
                  <img
                    src={iconBack}
                    alt={"back icon"}
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "0.5rem",
                    }}
                  />
                  back to experience
                </>
              }
            />
          </Link>
        </div>

        {/* <div className={styles.col_template}>
          <img src={iconExcel} alt={"excel"} />
          <div className={styles.btn_container}>
            <Button
              rounded
              variant={"outlined"}
              color="black"
              text={"Template JOOR ACC"}
            />
            <Button
              rounded
              variant={"outlined"}
              color="black"
              text={"Template JOOR RTW"}
            />
          </div>
        </div>
        <div className={styles.col_instructions}>
          <ul>
            {instructions.map((i, index) => {
              return (
                <li key={index}>
                  Step {index + 1} : {i}
                </li>
              );
            })}
          </ul>

          <div className={styles.btn_container}>
            <Button
              rounded
              variant={"outlined"}
              color="black"
              text={
                <div>
                  Download ACC
                  <img
                    src={iconCopy}
                    alt={"iconCopy"}
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                </div>
              }
              action={() => selectElementContents(accTableRef.current)}
            />
            <Button
              rounded
              variant={"outlined"}
              color="black"
              text={
                <div>
                  Download RTW
                  <img
                    src={iconCopy}
                    alt={"iconCopy"}
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                </div>
              }
              action={() => selectElementContents(papTableRef.current)}
            />
          </div>
          {showSuccessCopied && <span className={styles.copied}>Copied!</span>}
        </div>
   */}
      </div>
      {/* Formatting the HTML table but not displaying */}
      {/* <table className={styles.hidden_table} ref={papTableRef}>
        <thead>
          <tr>
            <th>retail price</th>
            <th>wholesale price</th>
            <th>quantity</th>
          </tr>
        </thead>
        <tbody>
          {order
            .filter((el) => el.joorOrderPap)
            .map((sku, index) => {
              return (
                <tr key={index}>
                  <td>100</td>
                  <td>100</td>
                  <td>{sku.quantity}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className={styles.hidden_table} ref={accTableRef}>
        <thead>
          <tr>
            <th>retail price</th>
            <th>wholesale price</th>
            <th>quantity</th>
          </tr>
        </thead>
        <tbody>
          {order
            .filter((el) => el.joorOrderAcc)
            .map((sku, index) => {
              return (
                <tr key={index}>
                  <td>100</td>
                  <td>100</td>
                  <td>{sku.quantity}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    */}
    </>
  );
}
