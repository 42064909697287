import React, { useEffect } from "react";
import { useBuyer } from "../../Context/ContextBuyer";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Shared/Loader";
import { useHistory } from "react-router-dom";

export default function Auth() {
  const { buyerActions } = useBuyer();

  const history = useHistory();

  let query = useQuery();

  const token = query.get("token");
  const page = query.get("page");

  if (!token && !page) {
    history.push("/");
  }

  useEffect(() => {
    const checkLink = async () => {
      const res = await buyerActions.checkMagicLink(token);
      if (res.ok) {
        history.push("/start-experience");
      } else {
        history.push("/");
      }
    };

    token && checkLink();
  }, [token]);

  return (
    <div id="loader">
      <Loader
        type="MutatingDots"
        color={"black"}
        secondaryColor={"grey"}
        height={150}
        width={150}
      />
    </div>
  );
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
