import React from "react";
import styles from "../Card.module.scss";

export default function CardSKU({ image, id, handleClick }) {
  return (
    <div className={styles.card_sku_container} onClick={handleClick}>
      <img src={image?.url} alt={id} />
      {/* <a href={sku.joor} target="_blank" rel="noopener noreferrer">
        <span>JOOR</span>
      </a> */}
    </div>
  );
}
