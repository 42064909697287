export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const countries = [
  "AUSTRALIA",
  "AUSTRIA",
  "AZERBAIJAN",
  "BELARUS",
  "BELGIUM",
  "BRAZIL",
  "CANADA",
  "CHINA",
  "COLOMBIA",
  "CYPRUS",
  "DENMARK",
  "FRANCE",
  "GERMANY",
  "GREECE",
  "HONG KONG",
  "IRELAND",
  "ISRAEL",
  "ITALY",
  "JAPAN",
  "KUWAIT",
  "LEBANON",
  "LITHUANIA",
  "LUXEMBOURG",
  "MACAO",
  "NORWAY",
  "PORTUGAL",
  "QATAR",
  "ROMANIA",
  "RUSSIA",
  "SERBIA",
  "SHANGHAI",
  "SINGAPORE",
  "SOUTH KOREA",
  "SPAIN",
  "SWEDEN",
  "SWITZERLAND",
  "TAIWAN",
  "TURKEY",
  "UAE",
  "UK",
  "UKRAINE",
  "US",
  "OTHER",
];

export const extractSymbol = (val) => {
  try {
    return val.substring(val.indexOf("(") + 1)[0];
  } catch (err) {
    return "";
  }
};

export const getPriceInCurrency = (prices, priceRetail, priceWholesale) => {
  if (!prices || !priceRetail || !priceWholesale) return null;

  let currency;

  if (priceRetail.includes("EUR")) currency = "WEUR-REUR";
  if (priceRetail.includes("JPY")) currency = "WEUR-RJPY";
  if (priceRetail.includes("GBP")) currency = "WEUR-RGBP";
  if (priceRetail.includes("BAS")) currency = "WBAS-REUR";
  if (priceRetail.includes("USL")) {
    if (priceWholesale.includes("USL")) {
      currency = "WUSL-RUSL";
    } else {
      currency = "WEUR-RUSL";
    }
  }
  if (priceRetail.includes("AED")) currency = "WEUR-RAED";
  if (priceRetail.includes("CNY")) currency = "WEUR-RCNY";
  if (priceRetail.includes("HKD")) currency = "WEUR-RHKD";
  if (priceRetail.includes("AUD")) currency = "WEUR-RAUD";
  if (priceRetail.includes("KRW")) currency = "WEUR-RKRW";
  if (priceRetail.includes("CNY")) currency = "WEXC-RCNY";

  let pricesByCurrency = prices?.find((p) => p.price_label === currency);

  //   return `${Math.round(getValue() * qty * 10) / 10}${extractSymbol(rawCurr)}`;

  return pricesByCurrency;
};

export const formatCurrency = (value, type) => {
  let formatted;

  if (type === "wholesale") {
    formatted = "W";
  } else {
    formatted = "R";
  }

  return `${formatted}${value}`.toUpperCase();
};

export const csvToJson = (csv) => {
  // isn't it what JSON.parce() doing?
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return result; //JSON
};

export const jsonToCsv = (json) => {
  var fields = Object.keys(json[0]);
  // var replacer = function (key, value) {
  //   return value === null ? "" : value;
  // };
  var csv = json.map(function (row) {
    return fields
      .map(function (fieldName) {
        return row[fieldName] || "";
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\n");
  return csv;
};

export const sortedImgs = (sku, size) => {
  const sizeOrDefault = (img) => {
    return img.thumbnails?.[size]?.url || img.url;
  };

  return sku.imagesId
    ?.sort((a, b) => a.ordreParSku - b.orderParSku)
    .map((img) => sizeOrDefault(img.attachment?.[0]));
};

export const spaceEachThreeNumbers = (x) => {
  if (!x) return x;
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};
