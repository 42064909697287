import React from "react";
import styles from "./Inspirations.module.scss";

export default function InspirationsCurators({ curators, select, selectedId }) {
  return (
    <div className={styles.curators_container}>
      {curators?.map((curator, index) => {
        return (
          <div
            className={`${styles.one_curator_container} ${
              selectedId === curator.id ? styles.selected : ""
            }`}
            key={index}
            onClick={() => select(curator)}
          >
            {selectedId === curator.id && (
              <div className={styles.circle_border} />
            )}
            <OneCurator curator={curator} />
          </div>
        );
      })}
    </div>
  );
}

const OneCurator = ({ curator }) => {
  return (
    <div
      className={styles.one_curator_image}
      style={{ backgroundImage: `url(${curator.cover?.[0]?.url})` }}
    />
  );
};
