import React from "react";
import styles from "./Card.module.scss";
import iconLiked from "../../assets/iconLiked.png";

export default function CardLookFrame({ lookFrame, isLiked }) {
  return (
    <div
      className={`${styles.card_look_frame_container}`}
      style={{
        backgroundImage: `url(${lookFrame.thumbnail?.[0].url})`,
        opacity:
          lookFrame.fillRatio === 0 ? 0.5 : lookFrame.fillRatio < 1 ? 0.8 : 0.9,
      }}
    >
      <div
        className={styles.frame_overlay}
        style={{
          width: `${(1 - lookFrame.fillRatio) * 100}%`,
        }}
      />
      <div className={styles.gradient_overlay} />
      <div className={styles.look_like_icon_container}>
        {isLiked && <img src={iconLiked} alt={"liked"} />}
      </div>
    </div>
  );
}
