import React, { useEffect, useState } from "react";
import MagnifyImage from "../../Shared/MagnifyImage";
import styles from "../Card.module.scss";
import CardSKU from "../CardSKU/CardSKU";
import CardSKURemove from "../CardSKU/CardSKURemove";
import CardSKURemoveDecision from "../CardSKU/CardSKURemoveDecision";
import CardSKUAddToOrder from "../CardSKU/CardSKUAddToOrder";
import CardSKUQuantity from "../CardSKU/CardSKUQuantity";
import { useDiary } from "../../../Context/ContextDiary";
import { useBuyer } from "../../../Context/ContextBuyer";
import { ReactComponent as AddIcon } from "../../../assets/button-add.svg";
import { useGlobal } from "../../../Context/ContextGlobal";
import gsap from "gsap";
import config from "../../../config/config";
import { ReactComponent as AddBlackIcon } from "../../../assets/button-add-black.svg";

const CardLookLiked = ({
  look,
  handleRemoveLook, // not sure if we need this anymore
  showAllSKUs,
  showBestSellers,
  handleCheckoutProductCard,
  inspiration,
  canAddToLiked,
  setShowProduct = null,
}) => {
  const { order, diaryActions, basket } = useDiary();
  const { buyer } = useBuyer();
  const { globalActions } = useGlobal();
  const [displayImageIndex, setDisplayImageIndex] = useState(0);

  const [indCardToShowDeleteModule, setIndCardToShowDeleteModule] = useState(
    null
  );

  const [
    showRemoveLookDecisionModal,
    setShowRemoveLookDecisionModal,
  ] = useState(null);

  const handleRemoveProductMiddleman = (e, sku) => {
    setIndCardToShowDeleteModule(null);
    diaryActions.removeSKU(basket.id, sku);
  };

  const removeLook = (e, look) => {
    e.stopPropagation();
    diaryActions.removeLook(look, buyer.id);
  };

  useEffect(() => {
    setIndCardToShowDeleteModule(null);
    setShowRemoveLookDecisionModal(null);
  }, [look]);

  return (
    <div className={styles.card_look_liked_container}>
      <div className={styles.image_container}>
        {inspiration && look.image?.[0].url && (
          <img src={look.image?.[0].url} alt={"models"} />
        )}
        {!inspiration && look.image?.[0].url && (
          <MagnifyImage
            imageUrl={look.image?.[displayImageIndex].url}
            alt={look.name}
            isDiary={true}
          />
        )}

        <div className={styles.add_button_wrap}>
          {canAddToLiked ? (
            <AddIcon
              onClick={() => {
                canAddToLiked && diaryActions.addLook(look, buyer.id);
              }}
            />
          ) : (
            <AddBlackIcon
              onClick={(e) => {
                console.log("handle remove look");
                // diaryActions.handleRemoveLook(e, look);
                setShowRemoveLookDecisionModal(true);
              }}
            />
          )}
        </div>

        <div className={styles.footnote_container}>
          {look.image?.length > 1 &&
            look.image?.map((img, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.img_dot}
                   ${displayImageIndex === index ? styles.active : ""}
                   `}
                  onClick={() => setDisplayImageIndex(index)}
                />
              );
            })}
        </div>

        {showAllSKUs || showBestSellers || !handleRemoveLook ? null : (
          <CardSKURemove
            handleRemove={() => {
              setShowRemoveLookDecisionModal(true);
            }}
          />
        )}
        {showRemoveLookDecisionModal ? (
          <CardSKURemoveDecision
            lookOrProduct={"look"}
            ok={(e) => {
              removeLook(e, look);
              setShowRemoveLookDecisionModal(null);
            }}
            cancel={() => setShowRemoveLookDecisionModal(null)}
          />
        ) : null}
      </div>
      {inspiration ? null : (
        <div className={styles.content_container}>
          <div className={styles.skus_grid}>
            {look?.products?.map((product, index) => {
              const selectedDiary = order?.find((d) => d.id === product.id);

              if (!product.orderedImages?.[0]?.url) return null;
              return (
                <div className={styles.sku_container} key={index}>
                  {product?.orderedImages[0] && (
                    <CardSKU
                      image={product.orderedImages[0]}
                      id={product.id}
                      handleClick={() => {
                        if (setShowProduct) {
                          setShowProduct(product);
                        } else if (handleCheckoutProductCard) {
                          handleCheckoutProductCard();
                          diaryActions.setModalProduct(true, product);
                        }
                      }}
                    />
                  )}
                  {selectedDiary ? (
                    <CardSKURemove
                      handleRemove={() => {
                        setIndCardToShowDeleteModule(index);
                      }}
                    />
                  ) : null}

                  <CardSKUAddToOrder
                    product={product}
                    added={selectedDiary}
                    handleRemove={() => setIndCardToShowDeleteModule(index)}
                  />

                  {typeof indCardToShowDeleteModule === "number" &&
                  indCardToShowDeleteModule === index ? (
                    <CardSKURemoveDecision
                      lookOrProduct={"product"}
                      ok={(e) => handleRemoveProductMiddleman(e, product)}
                      cancel={() => setIndCardToShowDeleteModule(null)}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardLookLiked;
