import React from "react";
import monogram from "../../assets/monogram.gif";

function Loader() {
  return (
    <div>
      <img
        src={monogram}
        alt={"Loading..."}
        style={{
          width: 150,
          height: 150,
        }}
      />
    </div>
  );
}

export default Loader;
