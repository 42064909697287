import React, { useEffect, useState } from "react";
import CardLookLiked from "../../Components/Card/CardLookLiked/CardLookLiked";
import { useDiary } from "../../Context/ContextDiary";
import { useLooks } from "../../Context/ContextLooks";
import { useBuyer } from "../../Context/ContextBuyer";

import styles from "./Diary.module.scss";

const DiaryLooksLiked = ({
  showAllSKUs,
  showBestSellers,
  handleCheckoutProductCard,
}) => {
  const { orderIds, basket, looksLikedIds, diaryActions } = useDiary();
  const { buyer } = useBuyer();

  const { looks, bestSellers } = useLooks();

  const [displayLooks, setDisplayLooks] = useState([]);

  useEffect(() => {
    if (!showBestSellers && !showAllSKUs) {
      // my selection
      console.log("here");
      setDisplayLooks(
        [...looks, ...bestSellers].filter((el) =>
          looksLikedIds.map((l) => l.id).includes(el.id)
        )
      );
    } else if (showAllSKUs) {
      setDisplayLooks(looks);
    } else if (showBestSellers) {
      setDisplayLooks(bestSellers);
    }
  }, [showBestSellers, showAllSKUs, looks, bestSellers, looksLikedIds]);

  const handleRemoveLook = (e, look) => {
    e.stopPropagation();
    if (isLiked(look.id)) diaryActions.removeLook(look, buyer.id);
    else diaryActions.addLook(look, buyer.id);
  };

  const handleRemoveProduct = (e, sku) => {
    e.stopPropagation();
    if (isSKULiked(sku.id)) diaryActions.removeSKU(basket.id, sku);
    else diaryActions.addSKU(basket.id, sku);
  };

  const isLiked = (id) => {
    return looksLikedIds.some((e) => e.id === id);
  };

  const isSKULiked = (id) => {
    return orderIds.includes(id);
  };

  const canLike = (l) => {
    return !looksLikedIds.map((el) => el.id).includes(l.id);
  };

  return (
    <>
      {displayLooks?.length === 0 && (
        <div style={{ margin: "1rem" }}>You didn't like any look yet.</div>
      )}
      <div className={styles.all_looks_container}>
        {displayLooks.map((look, index) => {
          return (
            <div className={styles.look_container} key={index}>
              <CardLookLiked
                isModalLook={false}
                look={look}
                canAddToLiked={canLike(look)}
                handleRemoveLook={handleRemoveLook}
                handleRemoveProduct={handleRemoveProduct}
                showAllSKUs={showAllSKUs}
                showBestSellers={showBestSellers}
                // handleCheckoutProductCard={() => console.log(" test ")}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
export default DiaryLooksLiked;
