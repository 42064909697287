import React, { useRef, useState } from "react";
import styles from "../../Modal.module.scss";
import { useBuyer } from "../../../../Context/ContextBuyer";
import { validateEmail } from "../../../../utils/utils";

export default function Initial({ handleChangeScreen, setEmail, email }) {
  const [isStartExperienceClicked, setisStartExperienceClicked] = useState(
    false
  );
  const [error, setError] = useState(null);

  const formRef = useRef();

  const { buyerActions } = useBuyer();

  return (
    <div className={styles.form_container}>
      <form
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          if (validateEmail(e.target[0].value)) {
            setError(null);
            setisStartExperienceClicked(true);

            if (!isStartExperienceClicked) {
              const res = await buyerActions.startExperience(email);

              if (res.ok) {
                if (res.data.access) {
                  handleChangeScreen("status");
                  setisStartExperienceClicked(false);
                } else {
                  handleChangeScreen("create");
                  setisStartExperienceClicked(false);
                }
              } else {
                setisStartExperienceClicked(false);
              }
            }
          } else {
            setError("You have entered an invalid email address!");
          }
        }}
      >
        <h1 className={styles.center}>
          <p className={styles.welcome_message}>
            Welcome to our Interactive Showroom integrated with JOOR. We hope
            you enjoy the FW22 Collection buying experience. Please log in.
          </p>
        </h1>
        <div className={styles.email_wrap}>
          <input
            required
            type="email"
            className={styles.fw_input}
            placeholder={"Email*"}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </div>
        {error && <div className={styles.error_container}>{error}</div>}

        <div className={styles.button_container}>
          <input
            className={styles.submit_btn}
            type="submit"
            value={"START EXPERIENCE"}
            submit
          />

          {/* <input
            className={styles.request_btn}
            type="submit"
            value={"CREATE ACCOUNT"}
            submit
          /> */}
        </div>
      </form>
    </div>
  );
}
