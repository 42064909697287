import React from "react";
import {useBuyer} from "../../Context/ContextBuyer";
import styles from "./DisguiseWrap.module.scss";
import {getItemStorage} from "../../utils/localStorage";

export default function DisguiseWrap({children}) {
  const {isDisguise, buyer, buyerActions} = useBuyer();

  const endDisguise = () => {
    buyerActions.applyDisguise();
  };

  return (
    <div>
      {isDisguise ? (
        <div className={styles.disguise}>
          You see the platforme as {buyer.mail}.{" "}
          <button onClick={endDisguise}>Exit →</button>
        </div>
      ) : null}
      {children}
    </div>
  );
}
