import React from "react";
import styles from "../Card.module.scss";
import { ReactComponent as AddToOrder } from "../../../assets/AddToOrderGrey.svg";
import { ReactComponent as AddToOrderPlus } from "../../../assets/AddToOrderGreyPlus.svg";
import { ReactComponent as AddedToOrder } from "../../../assets/addedToOrder.svg";

import { useDiary } from "../../../Context/ContextDiary";

export default function CardSKUAddToOrder({
  added,
  product,
  handleRemove,
  iconPlus,
}) {
  const { basket, diaryActions } = useDiary();
  return (
    <div
      className={styles.add_product}
      onClick={() =>
        added ? handleRemove() : diaryActions.addSKU(basket.id, product)
      }
    >
      {added ? (
        <AddedToOrder />
      ) : iconPlus ? (
        <AddToOrderPlus />
      ) : (
        <AddToOrder />
      )}
    </div>
  );
}
