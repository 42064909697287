import React from "react";
import ReactImageMagnify from "react-image-magnify";
import styles from "./MagnifyImage.module.scss";

export default function MagnifyImage({fw, imageUrl, alt, maxHeight, maxWidth}) {
  return (
    <ReactImageMagnify
      {...{
        enlargedImageContainerClassName: "enlargedImage",
        imageClassName: styles.image_style,
        className: styles.magnify_container,
        imageStyle: {
          maxHeight: maxHeight,
          maxWidth: maxWidth,
        },
        smallImage: {
          alt: alt,
          isFluidWidth: true,
          src: imageUrl,
        },
        largeImage: {
          src: imageUrl,
          width: maxWidth || 1400,
          height: maxHeight || 2100,
        },
        enlargedImageContainerDimensions: {
          width: maxWidth < 810 ? maxWidth : 810,
          height: "100%",
        },
        lensStyle: {
          background: "hsla(0, 0%, 100%, .3)",
        },
      }}
    />
  );
}
