import React from "react";
import styles from "../Modal.module.scss";
import {ReactComponent as ShiftLeft} from "../../../assets/shift-left.svg";
import {ReactComponent as ShiftRight} from "../../../assets/shift-right.svg";

export default function ModalNavControls({
  isFirst,
  isLast,
  handleClickPreviousLook,
  handleClickNextLook,
}) {
  return (
    <>
      {isFirst ? null : (
        <div className={styles.modal_nav_ctrl_left_container}>
          <ShiftLeft alt={"iconPrevious"} onClick={handleClickPreviousLook} />
        </div>
      )}

      {isLast ? null : (
        <div className={styles.modal_nav_ctrl_right_container}>
          <ShiftRight alt={"iconNext"} onClick={handleClickNextLook} />
        </div>
      )}
    </>
  );
}
