import React from "react";
import styles from "./Main.module.scss";

export default function MainVideoWaitEnd({ videoRef }) {
  const videoLoadStart = () => {
    console.log("video started loading");
    // show loading animation ..
  };

  const videoLoaded = () => {
    console.log("video ended loading");
    // remove loading animation ..
  };

  return (
    <div className={`${styles.video_wrapper}`}>
      <video
        ref={videoRef}
        className="player"
        preload="auto"
        src="https://storage.googleapis.com/paco-assets/PR_FW22_Horizontal_VALIDE_Small_Size_5mbits%20(1).mp4"
        autoPlay
        playsInline
        muted
        onLoadStart={videoLoadStart}
        onLoadedData={videoLoaded}
      ></video>
    </div>
  );
}
