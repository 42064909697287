import React, {useEffect, useState} from "react";
import styles from "../Modal.module.scss";
import logoWhite from "../../../assets/logoWhite.png";
import Initial from "./screens/Initial";
import Access from "./screens/Access";
import Status from "./screens/Status";
import Invitation from "./screens/Invitation";
import CreateAccount from "./screens/CreateAccount";
import {useHistory} from "react-router-dom";
import Disguise from './screens/Disguise'

export default function ModalLogin({error, statusMessage}) {
  const [screen, setScreen] = useState("init");
  const [email, setEmail] = useState("");

  const history = useHistory();

  useEffect(() => {
    history.location.pathname.includes("disguise") &&
      setScreen("disguise");
    history.location.pathname.includes("start-experience") &&
      setScreen("access");
    history.location.pathname.includes("invitation") && setScreen("invitation");
  }, [history.location.pathname]);

  const handleChangeScreen = (screen) => {
    setScreen(screen);
  };

  return (
    <div className={styles.modal_wrapper}>
      <div className={`${styles.modal} ${styles.modal_login}`}>
        {screen === "init" && (
          <Initial
            handleChangeScreen={handleChangeScreen}
            setEmail={setEmail}
            email={email}
          />
        )}
        {screen === "disguise" && <Disguise  />}
        {screen === "access" && <Access email={email} />}
        {screen === "create" && (
          <CreateAccount
            email={email}
            handleChangeScreen={handleChangeScreen}
          />
        )}        
        {screen === "status" && (
          <Status email={email} handleChangeScreen={handleChangeScreen} />
        )}
        {screen === "invitation" && (
          <Invitation email={email} handleChangeScreen={handleChangeScreen} />
        )}
      </div>
      {error && <div className={styles.error_container}>{error}</div>}
      {statusMessage && (
        <div className={styles.message_container}>{statusMessage}</div>
      )}
      {!history.location.pathname.includes("invitation") ? (
        <div className={styles.logo_unauth_container}>
          <img src={logoWhite} alt={"logoWhite"} />
        </div>
      ) : null}
    </div>
  );
}
