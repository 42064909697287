import React from "react";
import styles from "./Logo.module.scss";
import logo from "../../assets/monogram.png";
// import logo from "../../assets/logo.png";

export default function Logo({ dark }) {
  return (
    <div className={styles.logo_container}>
      <img
        alt={"logo"}
        src={logo}
        style={{
          filter: dark ? "invert(100%)" : "invert(0%)",
        }}
      />
    </div>
  );
}
