import React, { useEffect, useRef, useState } from "react";
import { useDiary } from "../../../Context/ContextDiary";
import { useGlobal } from "../../../Context/ContextGlobal";
import styles from "../Modal.module.scss";
import ModalNavControls from "./ModalNavControls";
import Logo from "../../Logo/Logo";
import { ReactComponent as ShopBag } from "../../../assets/Union.svg";
import { ReactComponent as Close } from "../../../assets/button-remove.svg";
import CardLookLiked from "../../Card/CardLookLiked/CardLookLiked";
import { gsap } from "gsap";
import config from "../.././../config/config";
import CardLookWithSKU from "../../Card/CardLookWIthSKU/CardLookWithSKU";

export default function ModalLook({
  fw,
  look,
  close,
  handleClickPreviousLook,
  handleClickNextLook,
  bestsellers = false,
}) {
  const [showProduct, setShowProduct] = useState(null);

  const { diaryActions, order } = useDiary();
  const { globalActions } = useGlobal();

  const { looksLikedIds } = useDiary();

  const containerRef = useRef(null);

  useEffect(() => {
    setShowProduct(null);
  }, [look]);

  useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const handleCloseModal = () => {
    // setIndexFirst(0);
    const tl = gsap.timeline();
    setTimeout(() => close(), 700);
    tl.to(containerRef.current, { duration: 0.7, marginTop: "-100%" });
    tl.to(containerRef.current, {
      display: "none",
    });
  };

  // const handleClickSku = (sku) => {
  //   if (clickedSku?.orderedImages?.[0].url === sku.orderedImages?.[0].url) {
  //     setClickedSku(null);
  //   } else setClickedSku(sku);
  //   setDisplayImageIndex(0);
  // };

  // const toggleLike = (e, look) => {
  //   e.stopPropagation();
  //   if (isLiked(look.id)) diaryActions.removeLook(look);
  //   else diaryActions.addLook(look, idBuyer);
  // };

  // const toggleLikeSKU = (e, sku) => {
  //   e.stopPropagation();
  //   if (isSKULiked(sku.id)) diaryActions.removeSKU(sku);
  //   else diaryActions.addSKU(sku);
  // };

  const canLike = (l) => {
    return !looksLikedIds.map((el) => el.id).includes(l.id);
  };

  // const isSKULiked = (id) => {
  //   return orderIds.includes(id);
  // };

  const handleScrollDown = () => {
    handleCloseModal();
    globalActions.showBestsellers(false);
    gsap.to(window, {
      duration: config.scrollTiming / 1000,
      scrollTo: "#scroll-next",
    });
  };

  useEffect(() => {
    if (look) {
      gsap.to(containerRef.current, {
        display: "block",
      });
      gsap.fromTo(
        containerRef.current,
        { marginTop: "-100%" },
        { duration: 0.7, marginTop: 0 }
      );
    }
  }, [look]);

  if (!look) return null;

  return (
    <div className={styles.modal_looks} ref={containerRef}>
      <div className={styles.navmenu_row}>
        <Logo dark />
        {bestsellers && <h2> best sellers - {look.name}</h2>}
        {!bestsellers && <h2>{look.name}</h2>}
        <div className={styles.navmenu_actions}>
          <div className={styles.shop_bag_wrap} onClick={handleScrollDown}>
            <ShopBag style={{ filter: "invert(100%)" }} />
            <span>{order.length || 0}</span>
          </div>
          <Close
            style={{ filter: "invert(100%)", cursor: "pointer" }}
            onClick={() => handleCloseModal()}
          />
        </div>
      </div>
      <div className={styles.all_looks_container}>
        {handleClickPreviousLook && handleClickNextLook && (
          <ModalNavControls
            order={look.order}
            handleClickPreviousLook={handleClickPreviousLook}
            handleClickNextLook={handleClickNextLook}
          />
        )}

        <div className={styles.look_container}>
          {showProduct ? ( // quick fix :-)
            <CardLookWithSKU
              look={look}
              canAddToLiked={canLike(look)}
              setModalProduct={diaryActions.setModalProduct}
              showAllSKUs={true}
              showProduct={showProduct}
              setShowProduct={setShowProduct}
            />
          ) : (
            <CardLookLiked
              isModalLook={true}
              look={look}
              canAddToLiked={canLike(look)}
              // handleRemoveLook={handleRemoveLook}
              // handleRemoveProduct={handleRemoveProduct}
              setModalProduct={diaryActions.setModalProduct}
              showAllSKUs={true}
              showProduct={showProduct}
              setShowProduct={setShowProduct}
            />
          )}
        </div>
      </div>
      {/* <div className={styles.grid_best_sellers}> */}

      {/* <div className={`${styles.image_container} `}>
          <MagnifyImage
            fw={fw}
            imageUrl={
              clickedSku?.orderedImages?.[displayImageIndex]?.url ||
              look.image?.[displayImageIndex]?.url
            }
            alt={look.name}
          />
          <CardSKULike
            isLiked={clickedSku ? isSKULiked(clickedSku.id) : isLiked(look.id)}
            toggleLike={(e) =>
              clickedSku ? toggleLikeSKU(e, clickedSku) : toggleLike(e, look)
            }
          />
          {clickedSku && (
            <div className={styles.img_dots_container}>
              <span className={styles.little_sku_name}>{clickedSku.sku}</span>
              {clickedSku?.orderedImages?.map((img, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.img_dot}
                   ${displayImageIndex === index ? styles.active : ""}
                   `}
                    onClick={() => setDisplayImageIndex(index)}
                  />
                );
              })}
              <span className={styles.little_sku_price}>
                Retail price:
                {getPriceInCurrency(currency.retail, clickedSku, 1, "retail")}
              </span>
            </div>
          )}
          {fw && !clickedSku && (
            <div className={styles.img_dots_container}>
              {look.image?.map((img, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.img_dot}
                   ${displayImageIndex === index ? styles.active : ""}
                   `}
                    onClick={() => setDisplayImageIndex(index)}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div ref={contentRef} className={styles.content_container}>
          <h2 onClick={() => setClickedSku(null)}>Look {look.order}</h2>
          <div className={`${styles.skus_grid} ${fw ? styles.is_fw_grid : ""}`}>
            {look.skUs.map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClickSku(el)}
                  className={`${styles.sku_container} ${
                    clickedSku?.id === el.id ? styles.clicked_sku : ""
                  }`}
                >
                  <CardSKU sku={el} />
                  <CardSKULike
                    isLiked={isSKULiked(el.id)}
                    toggleLike={(e) => toggleLikeSKU(e, el)}
                  />
                </div>
              );
            })}
          </div>
         </div> 
  
      </div> */}
    </div>
  );
}
