import React, { useReducer, useContext, useMemo } from "react";
import api from "../services/api";

const Diary = React.createContext();

const initialState = {
  basket: null,
  looksLiked: [],
  looksLikedIds: [],
  modal: {
    show: false,
  },
  wholesaleCurrency: "WEUR",
  retailCurrency: "REUR",
  product: null,
  wholesaleTotal: 0,
  retailTotal: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENCY":
      return Object.assign({}, state, {
        ...state,
        wholesaleCurrency: action.wholesaleCurrency,
        retailCurrency: action.retailCurrency,
      });
    case "SET_LOOKS_LIKED":
      return Object.assign({}, state, {
        ...state,
        looksLiked: action.looksLiked,
        looksLikedIds: action.looksLikedIds,
      });
    case "SET_MODAL_PRODUCT":
      return Object.assign({}, state, {
        ...state,
        modal: action.modal,
      });
    case "SET_PRODUCT":
      return Object.assign({}, state, {
        ...state,
        product: action.product,
      });
    case "SET_BASKET":
      return Object.assign({}, state, {
        ...state,
        basket: action.basket,
        wholesaleTotal: action.basket.wholesaleTotal,
        retailTotal: action.basket.retailTotal,
      });
    case "SET_WHOLESALE_TOTAL":
      return Object.assign({}, state, {
        ...state,
        wholesaleTotal: action.wholesaleTotal,
      });
    case "SET_RETAIL_TOTAL":
      return Object.assign({}, state, {
        ...state,
        retailTotal: action.retailTotal,
      });
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const DiaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      getProductData: async (id) => {
        dispatch({ type: "SET_PRODUCT", product: null });
        const res = await api.products.getProductById(id);
        dispatch({ type: "SET_PRODUCT", product: res });
      },
      addSKU: async (idBasket, sku) => {
        console.log(sku);
        let newDiary = [...state.basket.order] || [];
        newDiary.push({ ...sku });
        dispatch({
          type: "SET_BASKET",
          basket: { ...state.basket, order: newDiary },
        });
        // const neededKeys = ["id"];
        // console.log(
        //   Object.fromEntries(
        //     Object.entries(newDiary).filter(([key]) => neededKeys.includes(key))
        //   )
        // );
        await api.baskets.updateBasket(state.basket.id, {
          order: JSON.stringify(newDiary),
        });
      },
      removeSKU: async (idBasket, sku) => {
        let newDiary = [...state.basket.order] || [];
        newDiary = newDiary.filter((el) => el.id !== sku.id);
        dispatch({
          type: "SET_BASKET",
          basket: { ...state.basket, order: newDiary },
        });

        await api.baskets.updateBasket(state.basket.id, {
          order: JSON.stringify(newDiary),
        });
      },

      updateQuantity: async (idBasket, idSKU, quantity, product) => {
        let newDiary = [...state.basket.order] || [];
        let isAdded = false;
        newDiary = newDiary.map((d) => {
          if (d.id === idSKU) {
            d.quantity = quantity;
            isAdded = true;
          }
          return d;
        });
        if (!isAdded) {
          product.quantity = quantity;
          newDiary.push({ ...product });
          console.log(newDiary);
          dispatch({
            type: "SET_BASKET",
            basket: { ...state.basket, order: newDiary },
          });
        }

        console.log(newDiary);

        newDiary = newDiary.map((d) => {
          return {
            ...d,
            quantity_total: d.quantity
              ? Object.values(d.quantity)
                  .map((q) => q.quantity)
                  .reduce((acc, el) => acc + el, 0)
              : 0,
          };
        });

        console.log(isAdded);
        console.log(idSKU);
        console.log(quantity);
        console.log(newDiary);

        const b = await api.baskets.updateBasket(state.basket.id, {
          order: JSON.stringify(newDiary),
        });

        return b;
      },
      emptyBasket: async () => {
        dispatch({
          type: "SET_BASKET",
          basket: { ...state.basket, order: [] },
        });

        dispatch({
          type: "SET_RETAIL_TOTAL",
          retailTotal: 0,
        });
        dispatch({
          type: "SET_WHOLESALE_TOTAL",
          wholesaleTotal: 0,
        });

        const b = await api.baskets.updateBasket(state.basket.id, {
          order: JSON.stringify([]),
        });

        return b;
      },
      addLook: (look, idBuyer) => {
        let newLooksLikedIds = [...state.looksLikedIds];
        newLooksLikedIds.push({ id: look.id });
        dispatch({
          type: "SET_LOOKS_LIKED",
          looksLikedIds: newLooksLikedIds.sort((a, b) => a.order - b.order),
        });

        api.retailers.updateRetailerFavLook({
          buyerId: idBuyer,
          looksLiked: newLooksLikedIds.map((el) => el.id),
        });
      },
      removeLook: (look, idBuyer) => {
        let newLooksLikedIds = [...state.looksLikedIds];
        newLooksLikedIds = newLooksLikedIds.filter((el) => el.id !== look.id);
        dispatch({
          type: "SET_LOOKS_LIKED",
          looksLikedIds: newLooksLikedIds.sort((a, b) => a.order - b.order),
        });

        api.retailers.updateRetailerFavLook({
          buyerId: idBuyer,
          looksLiked: newLooksLikedIds.map((el) => el.id),
        });
      },
      setCurrency: (buyer) => {
        dispatch({
          type: "SET_CURRENCY",
          wholesaleCurrency: buyer.wholesaleCurrency,
          retailCurrency: buyer.retailCurrency,
        });
      },
      initDiary: (looksLikedIds, buyer) => {
        looksLikedIds &&
          dispatch({
            type: "SET_LOOKS_LIKED",
            looksLikedIds: looksLikedIds.sort((a, b) => a.order - b.order),
          });
        buyer &&
          dispatch({
            type: "SET_CURRENCY",
            wholesaleCurrency: buyer.wholesaleCurrency,
            retailCurrency: buyer.retailCurrency,
          });

        buyer && actions.getBasket(buyer.mail, false);
      },
      getBasket: async (email, noJoorOverwrite) => {
        const basketData = await api.baskets.getBasket(
          email || state.buyer.mail,
          noJoorOverwrite
        );

        const csvBasket = basketData?.order;
        const jsonBasket = JSON.parse(csvBasket);

        console.log(jsonBasket);

        if (jsonBasket?.length) {
          const quantityEnhanced = jsonBasket.map((sku) => {
            // let qtyTotal = 0;

            // if (sku.quantity && typeof sku.quantity === "number") {
            //   qtyTotal = sku.quantity;
            // } else if (sku.quantity) {
            //   qtyTotal = parseInt(
            //     Object.values(sku.quantity)
            //       .map((q) => q.quantity)
            //       .reduce((acc, el) => acc + el, 0)
            //   );
            // }

            return {
              ...sku,
              quantity: sku.quantity,
              // quantity_total: qtyTotal,
            };
          });

          dispatch({
            type: "SET_BASKET",
            basket: { ...basketData, order: quantityEnhanced || [] },
          });
          dispatch({
            type: "SET_RETAIL_TOTAL",
            retailTotal: basketData.retailTotal || 0,
          });
          dispatch({
            type: "SET_WHOLESALE_TOTAL",
            wholesaleTotal: basketData.wholesaleTotal || 0,
          });
        } else {
          dispatch({
            type: "SET_BASKET",
            basket: { ...basketData, order: jsonBasket || [] },
          });
          dispatch({
            type: "SET_RETAIL_TOTAL",
            retailTotal: basketData.retailTotal || 0,
          });
          dispatch({
            type: "SET_WHOLESALE_TOTAL",
            wholesaleTotal: basketData.wholesaleTotal || 0,
          });
        }
      },
      setModalProduct: (showHide, product) => {
        dispatch({ type: "SET_PRODUCT", product: null });
        dispatch({
          type: "SET_MODAL_PRODUCT",
          modal: {
            show: showHide,
            product,
          },
        });
      },
    }),
    [state]
  );

  const stateToReturn = {
    count: {
      order: state.basket?.order?.length,
      looksLiked: state.looksLiked?.length,
    },
    currency: {
      wholesale: state.wholesaleCurrency,
      retail: state.retailCurrency,
    },
    wholesaleTotal: state.wholesaleTotal,
    retailTotal: state.retailTotal,
    product: state.product,
    looksLiked: state.looksLiked,
    looksLikedIds: state.looksLikedIds,
    basket: state.basket,
    order: state.basket?.order,
    orderIds: state.basket?.order?.map((el) => el.id) || [],
    productModal: {
      show: state.modal.show,
      currency: {
        wholesale: state.wholesaleCurrency,
        retail: state.retailCurrency,
      },
      product: state.modal.product,
    },
    diaryActions: actions,
  };

  console.log("DIARY STATE", stateToReturn);

  return <Diary.Provider value={stateToReturn}>{children}</Diary.Provider>;
};

export const useDiary = () => useContext(Diary);
