import React from "react";
import Button from "../Button/Button";
import iconLogout from "../../assets/iconLogout2.png";
import { useBuyer } from "../../Context/ContextBuyer";
import styles from "./LoggedInToolbar.module.scss";
import Contact from "./Contact";
import Logo from "../Logo/Logo";

export default function Logout({ dark }) {
  const { buyerActions } = useBuyer();

  return (
    <div className={styles.container}>
      <Logo dark={dark} />
      <Contact dark={dark} />
      <Button
        noPadding
        variant={"textOnly"}
        text={
          <div
            className={`${dark ? styles.dark : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <img
              className={styles.logout_img}
              src={iconLogout}
              alt={"iconLogout"}
            />
            <span style={{ fontSize: "0.8rem" }}> LOG OUT</span>
          </div>
        }
        action={buyerActions.logout}
      />
    </div>
  );
}
