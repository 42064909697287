import React, {useEffect, useRef, useState} from "react";
import styles from "./VideoPlayer.module.scss";

export default function VideoPlayer({link}) {
  const videoRef = useRef(null);


  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    videoRef.current.play();
    videoRef.current.addEventListener("play", (event) => {
      setIsPlaying(true);
    });
    videoRef.current.addEventListener("pause", (event) => {
      setIsPlaying(false);
    });

    return () => {
      console.log("Cleaning");
      videoRef.current?.removeEventListener("play");
      videoRef.current?.removeEventListener("pause");
    };
  }, []);

  const videoLoadStart = () => {
    console.log("video started loading");
  };

  const videoLoaded = () => {
    console.log("video ended loading");
  };


  return (
    <div className={styles.page_container}>
       <div
      className={`${styles.video_wrapper} ${
        styles[isPlaying ? "pause" : "play"]
      }`}
    >
      <video
        controls
        ref={videoRef}
        className="player"
        preload="auto"
        src={link}
        // playsInline
        // muted
        onLoadStart={videoLoadStart}
        onLoadedData={videoLoaded}
        // onEnded={notifyVideoEnd}
      ></video>
    </div>
    </div>
  );
}
