import React, {useReducer, useContext, useMemo, useEffect} from "react";
import api from "../services/api";

const Looks = React.createContext();

const initialState = {
  looks: [],
  bestSellers: [],
  lookFrames: [],
  allProducts: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOOKS":
      return Object.assign({}, state, {
        ...state,
        looks: action.looks,
      });

    case "SET_BEST_SELLERS":
      return Object.assign({}, state, {
        ...state,
        bestSellers: action.looks,
      });
    case "SET_ALL_PRODUCTS":
      return Object.assign({}, state, {
        ...state,
        allProducts: action.products,
      });

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const LooksProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setLooks: (looks) => dispatch({type: "SET_LOOKS", looks}),
      setBestSellers: (looks) => {
        dispatch({type: "SET_BEST_SELLERS", looks});
      },
      setAllProducts: (products) =>
        dispatch({ type: "SET_ALL_PRODUCTS", products }),
    }),
    []
  );

  const fetchLooks = async () => {
    const res = await api.looks.getLooks();
    if (res?.looks) {
      const looks = res.looks
        .filter((a) => a.appearance && a.disappearance)
        .sort((a, b) => a.order - b.order);
      const bestSellers = res.looks
        .filter((a) => !a.appearance && !a.disappearance)
        .sort((a, b) => a.order - b.order);
      actions.setLooks(looks);
      actions.setBestSellers(bestSellers);

      let allProducts = [];
      looks.map((l) => {
        l.products.map((p) => {
          if (!allProducts.find((ap) => ap.id === p.id)) {
            allProducts.push(p);
          }
        });
      });
      bestSellers.map((l) => {
        l.products.map((p) => {
          if (!allProducts.find((ap) => ap.id === p.id)) {
            allProducts.push(p);
          }
        });
      });
      actions.setAllProducts(allProducts);
    }
  };

  useEffect(() => {
    fetchLooks();
  }, []);

  const stateToReturn = {
    looks: state.looks,
    bestSellers: state.bestSellers,
    allProducts: state.allProducts,
    looksActions: actions,
  };

  console.log("LOOKS STATE", stateToReturn);

  return <Looks.Provider value={stateToReturn}>{children}</Looks.Provider>;
};

export const useLooks = () => useContext(Looks);
